import React from 'react';

// material-ui
import { CircularProgress } from '@material-ui/core';



//-----------------------|| Loader ||-----------------------//

const Loader = () => {
   
    return (
        <div className='w_loder'>
            <CircularProgress  color="primary" />
        </div>
    );
};

export default Loader;
