import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { webSiteList } from '../../Services/service';
import { showToastError } from '../../store/constant';
import ClaimShipmentList from './claim-shipment-list';
import ReturnEntryList from './return-entry-list';
import WrongReturnEntryList from './wrong-return-entry-list';

const ReturnOrderList = () => {
    const {id} = useParams();
    const history= useHistory()
    const [active,setActive]= useState(1);
    const [option, setOption] = useState([]);;

    useEffect(() => {
        setActive(parseInt(id));
    }, [id]);

    useEffect(()=>{
        webSiteList()?.then((res) => {
            if (!res?.data?.error) {
                let item =[];
                res?.data?.data?.map(e=> {
                    item?.push({value : e?.id ,label : e?.name})
                })
                setOption(item);
            }
            else {
                showToastError(res?.data?.message)
            }
        })
    },[])
    return (
        <React.Fragment>
            <div className="white_bg w_pl-20 w_pr-20 w_pt-20 w_pb-0 css-11bu8qf-MuiPaper-root-MuiCard-root">
                <div className="row">
                    <div className="w_tabling">
                        <ul>
                            <li  onClick={()=>history?.push('/orders/return-order-list/1')} className={`${active === 1 ? 'w_active' : ''}`}>Return Received</li>
                            <li  onClick={()=>history?.push('/orders/return-order-list/2')} className={`${active === 2 ? 'w_active' : ''}`}>Wrong Return Received</li>
                            <li  onClick={()=>history?.push('/orders/return-order-list/3')} className={`${active === 3 ? 'w_active' : ''}`}>Claim Shipment</li>
                        </ul>
                    </div>
                </div>
                {active === 1 && <ReturnEntryList optionList={option || []}/>}
                {active === 2 && <WrongReturnEntryList optionList={option || []}/>} 
                {active === 3 && <ClaimShipmentList optionList={option || []}/>} 
            </div>
        </React.Fragment>
    );
};

export default ReturnOrderList;
