import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { webSiteList } from '../../Services/service';
import { showToastError } from '../../store/constant';
import AdvanceSummary from './advance-summary';
import DailySummary from './daily-summary';
import MisSummary from './mis-summary';

const Report = () => {
    const { id } = useParams();
    const history = useHistory();
    const [active, setActive] = useState(1);
    const [option, setOption] = useState([]);;
    useEffect(() => {
        setActive(parseInt(id));
    }, [id]);

    useEffect(()=>{
        webSiteList()?.then((res) => {
            if (!res?.data?.error) {
                let item =[];
                res?.data?.data?.map(e=> {
                    item?.push({value : e?.id ,label : e?.name})
                })
                setOption(item);
            }
            else {
                showToastError(res?.data?.message)
            }
        })
    },[])

    return (
        <React.Fragment>
            <div className="white_bg w_pl-20 w_pr-20 w_pt-20 w_pb-0 css-11bu8qf-MuiPaper-root-MuiCard-root">
                <div className="row">
                    <div className="w_tabling">
                        <ul>
                            <li onClick={() => history?.push('/report/1')} className={`${active === 1 ? 'w_active' : ''}`}>
                                Daily Summary
                            </li>
                            <li onClick={() => history?.push('/report/2')} className={`${active === 2 ? 'w_active' : ''}`}>
                                Advance Summary
                            </li>
                            <li onClick={() => history?.push('/report/3')} className={`${active === 3 ? 'w_active' : ''}`}>
                                Mis Summary
                            </li>
                        </ul>
                    </div>
                </div>
                {active === 1 && <DailySummary optionList={option || []}/>}
                {active === 2 && <AdvanceSummary optionList={option || []}/>}
                {active === 3 && <MisSummary optionList={option || []}/>}
            </div>
        </React.Fragment>
    );
};

export default Report;
