import React from 'react';

const bigAmount = React.memo(({settings, onChange, updateSettings}) => {
    return (
        <React.Fragment>
            {/* Big Amount */}
            <div className="row">
                <div className="col-lg-4 col-md-6 w_mb-30">
                    <label className="w_label">Big Amount</label>
                    <div className="form-input">
                        <input type="number" step={1} onChange={(e)=>onChange('big_amount', e?.target?.value)} value={settings?.big_amount} placeholder="Big Amount" className="form-control" />
                    </div>
                </div>
            </div>
            <div className="row w_mb-20">
                <div className="col-lg-4">
                    <button className="w-btn w-btn-blue" onClick={()=>updateSettings()}>save</button>
                    {/* <button className="w-btn w-btn-cancel w_ml-15">Cancel</button> */}
                </div>
            </div>
        </React.Fragment>
    );
});

export default bigAmount;
