import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import NotPicked from './not-picked';
import Delivered from './delivered';
import LiveOrder from './live-order';
import Returned from './returned';
import ReturnedDelivered from './returned-delivered';
import Cancelled from './cancelled';
import { webSiteList } from '../../Services/service';
import { showToastError } from '../../store/constant';
import SearchAll from './search';
import Print from './print';

const ManageOrder = () => {
    const { id } = useParams();
    const history = useHistory();
    const [active, setActive] = useState(8);
    const [option, setOption] = useState([]);
    const [accounts, setAccountList] = useState([]);

    useEffect(() => {
        setActive(parseInt(id));
    }, [id]);

    useEffect(() => {
        webSiteList()?.then((res) => {
            if (!res?.data?.error) {
                let item = [];
                res?.data?.data?.map((e) => {
                    item?.push({ value: e?.id, label: e?.name });
                });
                setOption(item);
            } else {
                showToastError(res?.data?.data?.error || res?.data?.message);
            }
        });
    }, []);

    // useEffect(()=>{
    //     accountList()?.then((res) => {
    //         if (!res?.data?.error) {
    //            let item =[];
    //            res?.data?.data?.rows?.map(e=>{
    //                item?.push({value:e?.id, label:e?.name})
    //            })
    //             setAccountList(item)
    //         }
    //         else {
    //             showToastError(res?.data?.message)
    //         }
    //     })
    // },[])

    useEffect(() => {
        setActive(parseInt(id));
    }, [id]);

    return (
        <React.Fragment>
            <div className="white_bg w_pl-20 w_pr-20 w_pt-20 w_pb-0 css-11bu8qf-MuiPaper-root-MuiCard-root">
                <div className="row">
                    <div className="w_tabling">
                        <ul>
                            <li onClick={() => history?.push('/manage-order/7')} className={`${active === 7 ? 'w_active' : ''}`}>
                                Search Order
                            </li>
                            <li onClick={() => history?.push('/manage-order/8')} className={`${active === 8 ? 'w_active' : ''}`}>
                                Print
                            </li>
                            <li onClick={() => history?.push('/manage-order/1')} className={`${active === 1 ? 'w_active' : ''}`}>
                                Not Picked
                            </li>
                            <li onClick={() => history?.push('/manage-order/2')} className={`${active === 2 ? 'w_active' : ''}`}>
                                Live
                            </li>
                            <li onClick={() => history?.push('/manage-order/3')} className={`${active === 3 ? 'w_active' : ''}`}>
                                Delivered
                            </li>
                            <li onClick={() => history?.push('/manage-order/4')} className={`${active === 4 ? 'w_active' : ''}`}>
                                Returned
                            </li>
                            <li onClick={() => history?.push('/manage-order/5')} className={`${active === 5 ? 'w_active' : ''}`}>
                                Returned Delivered
                            </li>
                            <li onClick={() => history?.push('/manage-order/6')} className={`${active === 6 ? 'w_active' : ''}`}>
                                Cancelled
                            </li>
                        </ul>
                    </div>
                </div>
                {active === 7 && <SearchAll optionList={option || []} accounts={accounts || []} />}
                {active === 8 && <Print optionList={option || []} accounts={accounts || []} />}
                {active === 1 && <NotPicked optionList={option || []} accounts={accounts || []} />}
                {active === 2 && <LiveOrder optionList={option || []} accounts={accounts || []} />}
                {active === 3 && <Delivered optionList={option || []} accounts={accounts || []} />}
                {active === 4 && <Returned optionList={option || []} accounts={accounts || []} />}
                {active === 5 && <ReturnedDelivered optionList={option || []} accounts={accounts || []} />}
                {active === 6 && <Cancelled optionList={option || []} accounts={accounts || []} />}
            </div>
        </React.Fragment>
    );
};

export default ManageOrder;
