import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import DateRangePicker from '@material-ui/lab/DateRangePicker';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import Pagination from '../../layout/Pagination';
import moment from 'moment';
import { useDebouncedEffect } from 'use-debounced-effect';
import { exportList, getDailySummryList, readOrder } from '../../Services/service';
import { showToastError, showToastSuccess } from '../../store/constant';
import Loader from '../../ui-component/Loader';

const DailySummary = React.memo(({ optionList }) => {
    const [value, setValue] = React.useState([null, null]);
    const [option, setOption] = useState(optionList);
    const [searchText, setSearchText] = useState();
    const [totoalRecords, setTotalRecords] = useState(0);
    const [isLoader, setLoader] = useState(false);
    const [allValues, setAllValues] = useState({
        website: '',
        currentPage: 1,
        limit: 50
    });

    const handle = {
        search: (e) => {
            setSearchText(e.target.value);
        },
        change: (e, name) => {
            setAllValues({ ...allValues, [name]: e });
        }
    };

    const [list, setList] = useState([
        {
            date: '15 July 2021',
            orderPicked: '15%',
            DELIVERED: '50%',
            LIVE: '80%',
            RTO: '20%'
        }
    ]);

    useDebouncedEffect(
        () => {
            readData();
        },
        1000,
        [searchText]
    );

    useEffect(() => {
        setOption(optionList);
    }, [optionList]);

    useEffect(() => {
        readData();
    }, [allValues, value]);

    const readData = () => {
        let params = {
            currentPage: allValues?.currentPage,
            limit: allValues?.limit
        };
        if (allValues?.webSite?.value) params.websiteId = allValues?.webSite?.value;
        if (searchText?.length >= 3) params.search = searchText;
        if (value[0]) params.startDate = value[0]?.valueOf();
        if (value[1]) params.endDate = value[1]?.valueOf();
        if (allValues?.order_method) params.order_method = allValues?.order_method?.value;

        getDailySummryList(params)?.then((res) => {
            if (!res?.data?.error) {
                setList(res?.data?.data?.rows);
                setTotalRecords(res?.data?.data?.count);
            } else {
                showToastError(res?.data?.data?.error || res?.data?.message);
            }
        });
    };

    const onChangePagination = (data) => {
        setAllValues({ ...allValues, currentPage: data.currentPage, limit: data.limit });
    };

    const exportOrder = (item) => {
        setLoader(true);
        let params = {
            startDate: moment(item?.shipping_date)?.valueOf(),
            endDate: moment(item?.shipping_date)?.valueOf()
        };
        if (searchText?.length >= 3) params.search = searchText;
        if (allValues?.account?.value) params.accountId = allValues?.account?.value;
        if (allValues?.website?.value) params.websiteId = allValues?.website?.value;

        exportList(params)?.then((res) => {
            if (!res?.data?.error) {
                setLoader(false);
                res?.data?.data?.file?.map((e) => {
                    window.open(e, '_self');
                });
                showToastSuccess('Exported Succesfully.');
            } else {
                setLoader(false);
                showToastError(res?.data?.data?.error || res?.data?.message);
            }
        });
    };

    return (
        <React.Fragment>
            {isLoader && <Loader />}
            <div className="row">
                <div className="col-2">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateRangePicker
                            disableFuture
                            label="Advanced keyboard"
                            value={value}
                            onChange={(newValue) => {
                                setValue(newValue);
                            }}
                            formatDate={(date) => moment(date).format('DD-MM-YYYY')}
                            renderInput={(startProps, endProps) => (
                                <React.Fragment>
                                    <input
                                        className="form-control"
                                        ref={startProps.inputRef}
                                        {...startProps.inputProps}
                                        placeholder="Start Date"
                                    />
                                    <Box sx={{ mx: 1 }}> To </Box>
                                    <input
                                        className="form-control"
                                        ref={endProps.inputRef}
                                        {...endProps.inputProps}
                                        placeholder="End Date"
                                    />
                                </React.Fragment>
                            )}
                        />
                    </LocalizationProvider>
                </div>
                <div className="col-2">
                    <div className="w_filters-serach">
                        <input
                            type="text"
                            className={`form-control ${searchText !== '' ? 'active' : ''}`}
                            placeholder="Search Order Id/ Phone No."
                            value={searchText ? searchText : ''}
                            onChange={handle.search}
                            autoComplete="off"
                        />
                        {searchText !== '' && (
                            <span className="w_delete-icon u_cp" onClick={() => setSearchText('')}>
                                X
                            </span>
                        )}
                    </div>
                </div>
                <div className="col-2">
                    <div className="form-input">
                        <Select
                            className="w_select"
                            placeholder="Select Website"
                            classNamePrefix="w_select"
                            isClearable={true}
                            isSearchable={true}
                            onChange={(e) => handle?.change(e, 'webSite')}
                            name="color"
                            options={option}
                            maxMenuHeight={110}
                        />
                    </div>
                </div>
            </div>

            <div className="row w_mt-10">
                <div className="col-12">
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>DONE ORDER</th>
                                    <th>CANCEL ORDER</th>
                                    <th>NOT PICKED</th>
                                    <th>LIVE</th>
                                    <th>DELIVERED</th>
                                    <th>RETURN</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {list?.map((item, i) => {
                                    return (
                                        <tr>
                                            <td>{moment(item?.shipping_date)?.format('DD/MM/YYYY')}</td>
                                            <td>
                                                {item?.[4] > 0 ? ((item?.[4] * 100) / item?.total)?.toFixed(2) + '%' : '0%'} ({item?.[4]})
                                            </td>
                                            <td>
                                                {item?.[8] > 0 ? ((item?.[8] * 100) / item?.total)?.toFixed(2) + '%' : '0%'} ({item?.[8]})
                                            </td>
                                            <td>
                                                {item?.[9] > 0 ? ((item?.[9] * 100) / item?.total)?.toFixed(2) + '%' : '0%'} ({item?.[9]})
                                            </td>
                                            <td>
                                                {item?.[10] > 0 ? ((item?.[10] * 100) / item?.total)?.toFixed(2) + '%' : '0%'}({item?.[10]})
                                            </td>
                                            <td>
                                                {item?.[11] > 0 ? ((item?.[11] * 100) / item?.total)?.toFixed(2) + '%' : '0%'}({item?.[11]})
                                            </td>
                                            <td>
                                                {item?.[12] > 0 ? ((item?.[12] * 100) / item?.total)?.toFixed(2) + '%' : '0%'}({item?.[12]})
                                            </td>
                                            <td>
                                                <button
                                                    className="w-table-button w-table-button-save w_mr-15"
                                                    onClick={() => exportOrder(item)}
                                                >
                                                    Download
                                                </button>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <Pagination onChange={onChangePagination} pageCount={totoalRecords} page={allValues?.currentPage} limit={allValues?.limit} />
        </React.Fragment>
    );
});
export default DailySummary;
