import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';

import SingleOrder from './single-order';
import DuplicateOrder from './duplicate-order';
import BigAmountOrder from './big-amount-order';
import ShortAddressOrder from './short-address-order';
import NotReceivedOrder from './not-received-order';
import DoneOrder from './done-order';
import { showToastError } from '../../store/constant';
import { webSiteList } from '../../Services/service';
import CancelOrder from './cancel-order';
import RepeatBuyerOrder from './repeat-buyer-order';
import WrongZIP from './no-zipcode';

//==============================|| SAMPLE PAGE ||==============================//

const AllOrder = () => {
    const { id } = useParams();
    const history = useHistory();
    const [active, setActive] = useState(1)
    const [option, setOption] = useState([]);;

    useEffect(() => {
        setActive(parseInt(id));
    }, [id]);
  
    useEffect(()=>{
        webSiteList()?.then((res) => {
            if (!res?.data?.error) {
                let item =[];
                res?.data?.data?.map(e=> {
                    item?.push({value : e?.id ,label : e?.name})
                })
                setOption(item);
            }
            else {
                showToastError(res?.data?.message)
            }
        })
    },[])

    return (
        <div className="white_bg w_pl-20 w_pr-20 w_pt-20 w_pb-0 css-11bu8qf-MuiPaper-root-MuiCard-root">
            <div className="row">
                <div className="w_tabling">
                    <ul>
                        {/* <li onClick={() => history?.push('/orders/all-order/1')} className={`${active === 1 ? 'w_active' : ''}`}>Single Order</li> */}
                        <li onClick={() => history?.push('/orders/all-order/2')} className={`${active === 2 ? 'w_active' : ''}`}>Duplicate Order</li>
                        <li onClick={() => history?.push('/orders/all-order/3')} className={`${active === 3 ? 'w_active' : ''}`}>Big Amount</li>
                        <li onClick={() => history?.push('/orders/all-order/4')} className={`${active === 4 ? 'w_active' : ''}`}>Short Address</li>
                        <li onClick={() => history?.push('/orders/all-order/5')} className={`${[5,20,21,22]?.includes(active) ? 'w_active' : ''}`}>Not Received</li>
                        {/* <li onClick={() => history?.push('/orders/all-order/6')} className={`${active === 6 ? 'w_active' : ''}`}>Repeat Buyer</li> */}
                        <li onClick={() => history?.push('/orders/all-order/7')} className={`${active === 7 ? 'w_active' : ''}`}>Done Order</li>
                        <li onClick={() => history?.push('/orders/all-order/8')} className={`${active === 8 ? 'w_active' : ''}`}>Cancel Order</li>
                        <li onClick={() => history?.push('/orders/all-order/9')} className={`${active === 9 ? 'w_active' : ''}`}>Wrong Pincode</li>
                    </ul>
                </div>
            </div>
            {/* {active === 1 && <SingleOrder optionList={option || []}/>} */}
            {active === 2 && <DuplicateOrder optionList={option || []}/>}
            {active === 3 && <BigAmountOrder optionList={option || []}/>}
            {active === 4 && <ShortAddressOrder optionList={option || []}/>}
            {[5,20,21,22]?.includes(active) && <NotReceivedOrder optionList={option || []}/>}
            {/* {active === 6 && <RepeatBuyerOrder optionList={option || []}/>} */}
            {active === 7 && <DoneOrder optionList={option || []}/>}
            {active === 8 && <CancelOrder optionList={option || []}/>}
            {active === 9 && <WrongZIP optionList={option || []}/>}
        </div>
    );
};

export default AllOrder;
