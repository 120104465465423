import React, { useEffect, useRef, useState } from 'react';
import Box from '@material-ui/core/Box';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import DateRangePicker from '@material-ui/lab/DateRangePicker';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import { accountList, exportList, getAccountList, readOrder, shipAllOrder, updateOrders, updateOrderStatus } from '../../Services/service';
import useDebouncedEffect from 'use-debounced-effect';
import { endDate, showToastError, showToastSuccess, startDate } from '../../store/constant';
import moment from 'moment';
import Pagination from '../../layout/Pagination';
import { useForceUpdate } from '../../hooks/useForceUpdate';
import SimpleReactValidator from 'simple-react-validator';
import Loader from '../../ui-component/Loader';
import SideModal from '../components/SideModal';
import { Paid } from '@material-ui/icons';

const DoneOrder = React.memo(({ optionList }) => {
    const forceUpdate = useForceUpdate();
    const [open, setOpen] = useState(false); /* Side modal open */
    const validator = useRef(new SimpleReactValidator({ element: (message, className) => <div className={'u_error'}>{message}</div> }));
    const validOrder = useRef(new SimpleReactValidator({ element: (message, className) => <div className={'u_error'}>{message}</div> }));
    const [value, setValue] = React.useState([startDate, endDate]);
    const [searchText, setSearchText] = useState();
    const [option, setOption] = useState(optionList);
    const [list, setList] = useState([]);
    const [account, setAccountList] = useState([]);
    const [totoalRecords, setTotalRecords] = useState(optionList);
    const [all, setAll] = useState(false);
    const [isLoader, setLoader] = useState(false);
    const [openError, setOpenError] = useState({});
    const [editData, setEditData] = useState(null);
    const [allValues, setAllValues] = useState({
        website: '',
        currentPage: 1,
        limit: 50,
        type: 1
    });

    // Close modal and remove modal from DOM
    const close = () => {
        setOpen(false);
    };

    const handle = {
        search: (e) => {
            setSearchText(e.target.value);
        },
        change: (e, name) => {
            setAllValues({ ...allValues, [name]: e });
        }
    };

    useDebouncedEffect(
        () => {
            readData();
        },
        1000,
        [searchText]
    );

    useEffect(() => {
        setOption(optionList);
    }, [optionList]);

    useEffect(() => {
        readData();
    }, [value, allValues?.website, allValues?.order_method]);

    useEffect(() => {
        if (list?.length > 1 && list?.every((e) => e?.selected == true)) setAll(true);
        else setAll(false);
    }, [list]);

    const setSeleted = (flag, items = []) => {
        let order = JSON.parse(JSON?.stringify(items));
        order?.map((e) => (e.selected = flag));
        setList(order);
    };

    const selectOrder = (value, id) => {
        let order = JSON.parse(JSON?.stringify(list));
        if (id && list?.length) {
            let i = list?.findIndex((e) => e?.orderId === id);
            if (i >= 0) {
                order[i].selected = value;
                setList(order);
            }
        }
    };

    const readData = () => {
        let params = {
            currentPage: allValues?.currentPage,
            limit: allValues?.limit,
            status: 4
        };
        if (allValues?.website?.value) params.websiteId = allValues?.website?.value;
        if (searchText?.length >= 3) params.search = searchText;
        if (value[0]) params.startDate = value[0]?.valueOf();
        if (value[1]) params.endDate = value[1]?.valueOf();
        if (allValues?.order_method) params.order_method = allValues?.order_method?.value;

        readOrder(params)?.then((res) => {
            if (!res?.data?.error) {
                setLoader(false);
                setSeleted(all, res?.data?.data?.rows);
                setTotalRecords(res?.data?.data?.count);
            } else {
                setLoader(false);
                showToastError(res?.data?.data?.error || res?.data?.message);
            }
        });
    };

    useEffect(() => {
        accountList()?.then((res) => {
            if (!res?.data?.error) {
                let item = [];
                res?.data?.data?.rows?.map((e) => {
                    item?.push({ value: e?.id, label: e?.name });
                });
                setAccountList(item);
            } else {
                showToastError(res?.data?.data?.error || res?.data?.message);
            }
        });
    }, []);

    const updateOrder = () => {
        if (validOrder?.current?.allValid() && editData) {
            updateOrders(editData)?.then((res) => {
                if (!res?.data?.error) {
                    setOpen(false);
                    readData();
                    showToastSuccess('Updated Succesfully.');
                } else {
                    showToastError(res?.data?.data?.error || res?.data?.message);
                }
            });
        } else {
            validOrder?.current?.showMessages();
            forceUpdate();
        }
    };

    const editOrder = (item) => {
        debugger;
        setEditData(item);
        setOpen(true);
    };

    const editChange = (e, name, type, i, status) => {
        let order = JSON.parse(JSON?.stringify(editData));
        let products = order?.products;
        if (!type) {
            setEditData({ ...editData, [name]: e?.target?.value });
        }
        if (type && products?.length > 0) {
            products[i][name] = e?.target?.value;
            setEditData({ ...editData, products });
        }
        if (status && order?.statusInfo) {
            order.statusInfo.note = e?.target?.value;
            setEditData({ ...editData, statusInfo: order?.statusInfo });
        }
    };

    const onChangePagination = (data) => {
        setAllValues({ ...allValues, currentPage: data.currentPage, limit: data.limit });
        let params = {
            currentPage: data?.currentPage,
            limit: data?.limit,
            status: 4
        };
        if (allValues?.website?.value) params.websiteId = allValues?.website?.value;
        if (searchText?.length >= 3) params.search = searchText;
        if (value[0]) params.startDate = value[0]?.valueOf();
        if (value[1]) params.endDate = value[1]?.valueOf();
        if (allValues?.order_method) params.order_method = allValues?.order_method?.value;

        readOrder(params)?.then((res) => {
            if (!res?.data?.error) {
                setLoader(false);
                setSeleted(all, res?.data?.data?.rows);
                setTotalRecords(res?.data?.data?.count);
            } else {
                setLoader(false);
                showToastError(res?.data?.data?.error || res?.data?.message);
            }
        });
    };

    const orderCancel = (id, status) => {
        let param = {
            orderId: id,
            status: status || 8
        };
        updateOrderStatus(param)?.then((res) => {
            if (!res?.data?.error) {
                readData();
                showToastSuccess('Updated Succesfully.');
            } else {
                showToastError(res?.data?.data?.error || res?.data?.message);
            }
        });
    };

    const shipOrders = () => {
        if (validator?.current?.allValid()) {
            setLoader(true);
            let params = {
                shipBy: allValues?.type,
                accountId: allValues?.account?.value,
                websiteId: allValues?.website?.value
            };

            if (!all) {
                let orderIds = [];
                list?.map((_) => {
                    if (_?.selected) {
                        orderIds?.push(_?.orderId);
                    }
                });
                params.orderIds = orderIds;
            } else {
                if (searchText?.length >= 3) params.search = searchText;
                if (value[0]) params.startDate = value[0]?.valueOf();
                if (value[1]) params.endDate = value[1]?.valueOf();
                if (allValues?.order_method) params.order_method = allValues?.order_method?.value;
            }
            shipAllOrder(params)?.then((res) => {
                console.log('🚀 ~ shipAllOrder ~ res:', res);
                if (!res?.data?.error) {
                    setLoader(false);
                    readData();
                    setOpenError({ openError, open: true, data: res?.data });
                    // showToastSuccess('Shipped Succesfully.');
                } else {
                    setLoader(false);
                    showToastError(res?.data?.data?.error || res?.data?.message);
                }
            });
        } else {
            validator?.current?.showMessages();
            forceUpdate();
        }
    };

    const shipSingleOrder = (ids) => {
        if (validator?.current?.allValid()) {
            setLoader(true);
            let params = {
                shipBy: allValues?.type,
                accountId: allValues?.account?.value,
                websiteId: allValues?.website?.value,
                orderIds: [ids]
            };
            shipAllOrder(params)?.then((res) => {
                console.log('ORDER res: ', JSON.stringify(res));
                if (!res?.data?.error) {
                    setLoader(false);
                    readData();
                    setOpenError({ openError, open: true, data: res?.data });
                } else {
                    setLoader(false);
                    showToastError(res?.data?.data?.error || res?.data?.message);
                }
            });
        } else {
            validator?.current?.showMessages();
            forceUpdate();
        }
    };

    const exportOrder = () => {
        setLoader(true);
        let params = {
            shipBy: allValues?.type,
            status: 4
        };
        if (searchText?.length >= 3) params.search = searchText;
        if (value[0]) params.startDate = value[0]?.valueOf();
        if (value[1]) params.endDate = value[1]?.valueOf();
        if (allValues?.order_method) params.order_method = allValues?.order_method?.value;
        if (allValues?.account?.value) params.accountId = allValues?.account?.value;
        if (allValues?.website?.value) params.websiteId = allValues?.website?.value;

        exportList(params)?.then((res) => {
            if (!res?.data?.error) {
                setLoader(false);
                res?.data?.data?.file?.map((e) => {
                    window.open(e, '_self');
                });
                showToastSuccess('Exported Succesfully.');
            } else {
                setLoader(false);
                showToastError(res?.data?.data?.error || res?.data?.message);
            }
        });
    };
    return (
        <React.Fragment>
            {isLoader && <Loader />}
            <div className="row">
                <div className="col-2">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateRangePicker
                            disableFuture
                            label="Advanced keyboard"
                            value={value}
                            onChange={(newValue) => {
                                setValue(newValue);
                            }}
                            formatDate={(date) => moment(date).format('DD-MM-YYYY')}
                            renderInput={(startProps, endProps) => (
                                <React.Fragment>
                                    <input
                                        className="form-control"
                                        ref={startProps.inputRef}
                                        {...startProps.inputProps}
                                        placeholder="Start Date"
                                    />
                                    <Box sx={{ mx: 1 }}> To </Box>
                                    <input
                                        className="form-control"
                                        ref={endProps.inputRef}
                                        {...endProps.inputProps}
                                        placeholder="End Date"
                                    />
                                </React.Fragment>
                            )}
                        />
                    </LocalizationProvider>
                </div>
                <div className="col-2">
                    <div className="w_filters-serach">
                        <input
                            type="text"
                            className={`form-control ${searchText !== '' ? 'active' : ''}`}
                            placeholder="Search Order Id/ Phone No."
                            value={searchText ? searchText : ''}
                            onChange={handle.search}
                            autoComplete="off"
                        />
                        {searchText !== '' && (
                            <span className="w_delete-icon u_cp" onClick={() => setSearchText('')}>
                                X
                            </span>
                        )}
                    </div>
                </div>
                <div className="col-2">
                    <div className="w_flex w_align-center w_100">
                        <div className="form-input w_100">
                            <Select
                                className="w_select"
                                placeholder="Select Website"
                                classNamePrefix="w_select"
                                isClearable={true}
                                isSearchable={true}
                                onChange={(e) => handle?.change(e, 'website')}
                                name="color"
                                options={option}
                                maxMenuHeight={110}
                            />
                            <span className="w_error">{validator?.current?.message('website', allValues?.website, 'required')}</span>
                        </div>
                        <div className="w_export-icon" onClick={() => exportOrder()}>
                            <img src="/img/export.svg" />
                        </div>
                    </div>
                </div>

                <div className="col-2">
                    <button className="w-btn w-btn-green w_100" onClick={() => shipOrders()}>
                        Ship All Order
                    </button>
                </div>
            </div>
            <div className="row  w_mt-20">
                <div className="col-2">
                    <div className="form-input">
                        <Select
                            className="w_select"
                            placeholder="Select Account"
                            classNamePrefix="w_select"
                            isClearable={true}
                            isSearchable={true}
                            onChange={(e) => handle?.change(e, 'account')}
                            name="color"
                            options={account}
                            maxMenuHeight={110}
                        />
                        <span className="w_error">{validator?.current?.message('account', allValues?.account, 'required')}</span>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 w_mb-30">
                    <label className="w_label">Select Delivery</label>
                    <div className="w_flex w_align-items-center">
                        <label className="w-radio">
                            Pincodewise
                            <input
                                type="radio"
                                name="radio"
                                checked={allValues?.type == 1}
                                onChange={(e) => handle?.change(e?.target?.checked ? 1 : 2, 'type')}
                            />
                            <span className="checkmark"></span>
                        </label>
                        <label className="w-radio">
                            Manual
                            <input
                                type="radio"
                                name="radio"
                                checked={allValues?.type == 2}
                                onChange={(e) => handle?.change(e?.target?.checked ? 2 : 1, 'type')}
                            />
                            <span className="checkmark"></span>
                        </label>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 w_mb-30">
                    <label className="w_label">Paid/COD:</label>
                    <div className="form-input">
                        <Select
                            className="w_select"
                            placeholder="Select Filter"
                            classNamePrefix="w_select"
                            value={allValues?.order_method}
                            isClearable={true}
                            isSearchable={true}
                            onChange={(e) => handle?.change(e, 'order_method')}
                            name="color"
                            options={[
                                { label: 'PREPAID', value: 2 },
                                { label: 'COD', value: 1 }
                            ]}
                            maxMenuHeight={110}
                        />
                    </div>
                </div>
            </div>
            <div className="row w_mt-10">
                <div className="col-12">
                    <div className="table-responsive">
                        <span>{totoalRecords || 0} Records</span>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>
                                        <label className="w_checkbox">
                                            <input
                                                type="checkbox"
                                                checked={all}
                                                onChange={(e) => {
                                                    setAll(e?.target?.checked);
                                                    setSeleted(e?.target?.checked, list);
                                                }}
                                            />
                                            <span className="checkmark"></span>
                                        </label>
                                    </th>
                                    <th>Order Details</th>
                                    <th>Customer Details</th>
                                    <th>Customer Address</th>
                                    <th>Details SKU</th>
                                    <th>Staff Notes</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {list?.map((item, i) => {
                                    let skuId = item?.products
                                        ?.map((e) => {
                                            return `${e?.sku}(${e?.quantity})`;
                                        })
                                        .join(', ');
                                    return (
                                        <tr key={item?.orderId}>
                                            <td>
                                                <label className="w_checkbox">
                                                    <input
                                                        type="checkbox"
                                                        checked={item?.selected}
                                                        id={item?.orderId + i}
                                                        onChange={(e) => selectOrder(e?.target?.checked, item?.orderId)}
                                                    />
                                                    <span className="checkmark"></span>
                                                </label>
                                            </td>
                                            <td>
                                                <div className="w_flex w_align-items-center w_mb-5 w_cp">
                                                    <span className="w-color-blue w_mr-5 flex-shrink">Order ID : </span>{' '}
                                                    {item?.order_number}
                                                </div>
                                                <span className="w_block w_mb-5">
                                                    {item?.order_date && moment(item?.order_date)?.format('DD MMMM, YY')}
                                                </span>
                                                <span className="w_block w_mb-5 w_text-c">
                                                    {optionList?.find((e) => e?.value == item?.website)?.label}
                                                </span>
                                                <span className="w_block w_mb-5 w_text-c">
                                                    {item?.state} - {item?.zipcode}
                                                </span>
                                            </td>
                                            <td>
                                                <div className="w_flex w_align-items-center w_mb-5">
                                                    <span className="w-color-blue w_mr-5 flex-shrink">Customer : </span> {item?.full_name}
                                                </div>
                                                <div className="w_block w_mb-5">
                                                    <Link to={''}>{item?.phone}</Link>
                                                </div>
                                                <span className="w_block">{item?.alter_phone}</span>
                                                <div className="w_block w_mb-5">
                                                    <Link to={''}>{item?.email}</Link>
                                                </div>
                                            </td>
                                            <td className="w_note">
                                                {/* <textarea
                                                        className="form-control form-control-table-textarea"
                                                        Placeholder="Address"
                                                        value={item?.address}
                                                        maxLength={200}
                                                        onChange={(e)=>updateOrder(e.target.value,item?.orderId, 'address')}
                                                    /> */}
                                                <span className="w_block w_mb-5">{item?.address}</span>
                                            </td>
                                            <td>
                                                <span className="w_block w_mb-5">Rs. :{item?.order_amount}</span>
                                                <span className="w_block w_mb-5"> SKU (QTY):</span>
                                                <span className="w_block w_mb-5"> {skuId} </span>

                                                {
                                                    <span className="w_block table-label yellow-label">
                                                        {item?.order_method == 1 ? 'COD' : 'PREPAID'}
                                                    </span>
                                                }
                                            </td>
                                            <td className="w_note">
                                                <span className="w_block w_mb-5">{item?.statusInfo?.note}</span>
                                                {item?.statusInfo?.updatedBy?.firstName && (
                                                    <span className="w_block w_mb-5">
                                                        -by:{' '}
                                                        {item?.statusInfo?.updatedBy &&
                                                            item?.statusInfo?.updatedBy?.firstName +
                                                                ' ' +
                                                                item?.statusInfo?.updatedBy?.lastName}
                                                        {item?.statusInfo?.updatedAt &&
                                                            moment(item?.statusInfo?.updatedAt).format('DD MMM YYYY, hh:mm A')}
                                                    </span>
                                                )}
                                            </td>
                                            <td>
                                                <button
                                                    className="w-table-button w-table-button-white w_mr-15"
                                                    onClick={() => editOrder(item)}
                                                >
                                                    Edit
                                                </button>{' '}
                                                <br />
                                                <button
                                                    className="w-table-button w-table-button-save w_mr-15"
                                                    onClick={() => shipSingleOrder(item?.orderId)}
                                                >
                                                    Ship Now
                                                </button>
                                                <br />
                                                <button
                                                    className="w-table-button w-table-button-danger"
                                                    onClick={() => orderCancel(item?.orderId, 8)}
                                                >
                                                    Order Cancel
                                                </button>
                                                <br />
                                                <button
                                                    className="w-table-button w-table-button-yellow"
                                                    onClick={() => orderCancel(item?.orderId, 5)}
                                                >
                                                    Not Received
                                                </button>
                                                <br />
                                                <button
                                                    className="w-table-button w-table-button-yellow"
                                                    onClick={() => orderCancel(item?.orderId, 1)}
                                                >
                                                    Short Address
                                                </button>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <Pagination onChange={onChangePagination} pageCount={totoalRecords} page={allValues?.currentPage} limit={allValues?.limit} />

            {/* Side modal */}
            {open && (
                <SideModal
                    open={open}
                    name={'Update Order'}
                    maxWidth={900}
                    onClose={() => close()}
                    header={<h4>Update Order</h4>}
                    footer={
                        <div className="row w_pl-20 w_pr-20">
                            <div className="u_col-3">
                                <button className={`w-btn w-btn-green`} onClick={() => updateOrder()}>
                                    Save
                                </button>
                            </div>
                            <div className="u_col-3">
                                <button className={`w-btn w-btn-danger w_ml-20`} onClick={() => close()}>
                                    Cancel
                                </button>
                            </div>
                            <div className="u_col-6"></div>
                        </div>
                    }
                >
                    <div className="w_pl-20 w_pt-20 w_pb-20 w_pr-20">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 w_mb-20">
                                <label className="w_label">Order ID</label>
                                <div className="form-input">
                                    <input
                                        type="text"
                                        onChange={(e) => editChange(e, 'order_number')}
                                        value={editData?.order_number}
                                        placeholder="Order ID"
                                        className="form-control"
                                    />
                                </div>
                                <span className="w_error">
                                    {validOrder?.current?.message('order_number', editData?.order_number, 'required')}
                                </span>
                            </div>
                            <div className="col-lg-4 col-md-6 w_mb-20">
                                <label className="w_label">Customer Name</label>
                                <div className="form-input">
                                    <input
                                        type="text"
                                        onChange={(e) => editChange(e, 'full_name')}
                                        value={editData?.full_name}
                                        placeholder="Customer Name"
                                        className="form-control"
                                    />
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 w_mb-20">
                                <label className="w_label">Staff Note</label>
                                <textarea
                                    value={editData?.statusInfo?.note}
                                    onChange={(e) => editChange(e, 'note', null, null, true)}
                                    placeholder="Customer Address"
                                    className="form-control form-control-table-textarea"
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-4 col-md-6 w_mb-20">
                                <label className="w_label">State</label>
                                <div className="form-input">
                                    <input
                                        type="string"
                                        onChange={(e) => editChange(e, 'state')}
                                        value={editData?.state}
                                        placeholder="State"
                                        className="form-control"
                                    />
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 w_mb-20">
                                <label className="w_label">Pincode</label>
                                <div className="form-input">
                                    <input
                                        type="number"
                                        onChange={(e) => editChange(e, 'zipcode')}
                                        value={editData?.zipcode}
                                        placeholder="zipcode"
                                        className="form-control"
                                    />
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 w_mb-20">
                                <label className="w_label">City</label>
                                <div className="form-input">
                                    <input
                                        type="string"
                                        onChange={(e) => editChange(e, 'city')}
                                        value={editData?.city}
                                        placeholder="city"
                                        className="form-control"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-4 col-md-6 w_mb-20">
                                <label className="w_label">Mobile Number</label>
                                <div className="form-input">
                                    <input
                                        type="number"
                                        onChange={(e) => editChange(e, 'phone')}
                                        value={editData?.phone}
                                        placeholder="Mobile Number"
                                        className="form-control"
                                    />
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 w_mb-20">
                                <label className="w_label">Email</label>
                                <div className="form-input">
                                    <input
                                        type="email"
                                        onChange={(e) => editChange(e, 'email')}
                                        value={editData?.email}
                                        placeholder="Email"
                                        className="form-control"
                                    />
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 w_mb-20">
                                <label className="w_label">Other Number</label>
                                <div className="form-input">
                                    <input
                                        type="number"
                                        onChange={(e) => editChange(e, 'alter_phone')}
                                        value={editData?.alter_phone}
                                        placeholder="Other Number"
                                        className="form-control"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-4 col-md-6 w_mb-20">
                                <label className="w_label">Customer Address</label>
                                <textarea
                                    value={editData?.address}
                                    onChange={(e) => editChange(e, 'address')}
                                    placeholder="Customer Address"
                                    className="form-control form-control-table-textarea"
                                />
                            </div>
                        </div>
                        {editData?.products?.map((e, i) => {
                            return (
                                <div className="row">
                                    <div className="col-lg-4 col-md-6 w_mb-20">
                                        <label className="w_label">SKU Number</label>
                                        <div className="form-input">
                                            <input
                                                type="text"
                                                onChange={(e) => editChange(e, 'sku', true, i)}
                                                value={e?.sku}
                                                placeholder="SKU Number"
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 w_mb-20">
                                        <label className="w_label">Product QTY</label>
                                        <div className="form-input">
                                            <input
                                                type="number"
                                                onChange={(e) => editChange(e, 'quantity', true, i)}
                                                value={e?.quantity}
                                                placeholder="Product QTY"
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 w_mb-20">
                                        <label className="w_label">Price</label>
                                        <div className="form-input">
                                            <input
                                                type="number"
                                                value={e?.price}
                                                onChange={(e) => editChange(e, 'price', true, i)}
                                                placeholder="Price"
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </SideModal>
            )}

            {openError?.open && (
                <SideModal
                    open={open}
                    name={'Status'}
                    maxWidth={800}
                    onClose={() => setOpenError({ ...openError, open: false, data: null })}
                    header={<h4>{`Success: ${openError?.data?.data?.success || 0}, Failed: ${openError?.data?.data?.failed || 0}`}</h4>}
                    footer={
                        <div className="row w_pl-20 w_pr-20">
                            <div className="u_col-3">
                                <button
                                    className={`w-btn w-btn-danger w_ml-20`}
                                    onClick={() => setOpenError({ ...openError, open: false, data: null })}
                                >
                                    Close
                                </button>
                            </div>
                            <div className="u_col-6"></div>
                        </div>
                    }
                >
                    <div className="w_pl-20 w_pt-20 w_pb-20 w_pr-20">
                        {openError?.data?.data?.errorList?.length >= 0 ? (
                            <>
                                {openError?.data?.data?.errorList?.map((e) => {
                                    if (typeof e === 'string') {
                                        return (
                                            <>
                                                <span>{e}</span> <br />
                                            </>
                                        );
                                    }
                                    if (typeof e === 'object') {
                                        return (
                                            <>
                                                <span>{JSON.stringify(e)}</span>
                                                <br />
                                            </>
                                        );
                                    }
                                })}
                            </>
                        ) : (
                            <></>
                        )}
                    </div>
                </SideModal>
            )}
        </React.Fragment>
    );
});
export default DoneOrder;
