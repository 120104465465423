import axios from 'axios';
import { baseURL, getToken } from '../store/constant';

const instance = axios.create();

export const setSession = (accessToken, user) => {
    if (accessToken) {
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('user', user);
    } else {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('user');
        delete instance.defaults.headers.common.Authorization;
    }
};
const getHeader = () => {
    if (getToken()) {
        instance.defaults.headers.common.Authorization = `Bearer ${getToken()}`;
    }
};
export const login = async (data) => {
    return await instance.post(baseURL + '/auth/login', data);
};

export const addUser = async (data) => {
    getHeader();
    return await instance.post(baseURL + '/auth/register', data);
};

export const updateUser = async (data) => {
    getHeader();
    return await instance.post(baseURL + '/auth/update', data);
};

export const userList = async (data) => {
    getHeader();
    return await instance.get(baseURL + '/auth/users', data);
};

export const addAccount = async (data) => {
    getHeader();
    return await instance.post(baseURL + '/settings/account/create', data);
};

export const updateAccount = async (data) => {
    getHeader();
    return await instance.put(baseURL + '/settings/account/update', data);
};
export const deleteAccount = async (id) => {
    return await instance.delete(baseURL + '/settings/account/delete/' + id);
};

export const accountList = async (data) => {
    getHeader();
    return await instance.get(baseURL + '/settings/account', data);
};

export const getMasterSettings = async (data) => {
    getHeader();
    return await instance.get(baseURL + '/settings/other', data);
};

export const updateMasterSettings = async (data) => {
    getHeader();
    return await instance.put(baseURL + '/settings/other/update', data);
};

export const addWebsite = async (data) => {
    getHeader();
    return await instance.post(baseURL + '/settings/website/create', data);
};

export const updateWebsite = async (data) => {
    getHeader();
    return await instance.put(baseURL + '/settings/website/update', data);
};

export const getWebsite = async (data) => {
    getHeader();
    return await instance.get(baseURL + '/settings/website');
};

export const deleteWebsite = async (data) => {
    getHeader();
    return await instance.post(baseURL + '/settings/website/delete', data);
};

export const getAccountList = async (data) => {
    getHeader();
    return await instance.post(baseURL + '/settings/account/selector', data);
};

export const exportPincode = async (data) => {
    getHeader();
    return await instance.post(baseURL + '/settings/pincode/export', data);
};

export const importPincode = async (data) => {
    getHeader();
    return await instance.post(baseURL + '/settings/pincode/import', data);
};

export const uploadCSV = async (data) => {
    getHeader();
    return await instance.post(baseURL + '/order/upload', data);
};

export const uploadShopifyCSV = async (data) => {
    getHeader();
    return await instance.post(baseURL + '/order/shopify-order', data);
};

export const getAllShopifyOrder = async (data) => {
    getHeader();
    return await instance.post(baseURL + '/order/shopify/all', data);
};

export const getSingleShopifyOrder = async (data) => {
    getHeader();
    return await instance.post(baseURL + '/order/shopify/single', data);
};

export const webSiteList = async (data) => {
    getHeader();
    return await instance.post(baseURL + '/settings/website/list', data);
};

export const readOrder = async (data) => {
    getHeader();
    return await instance.post(baseURL + '/order/read', data);
};

export const updateOrderStatus = async (data) => {
    getHeader();
    return await instance.post(baseURL + '/order/status', data);
};

export const deleteOrder = async (id) => {
    getHeader();
    return await instance.delete(baseURL + '/order/delete/' + id);
};

export const multipleOrderUpdate = async (data) => {
    getHeader();
    return await instance.post(baseURL + '/order/status/multi', data);
};

export const allOrderUpdate = async (data) => {
    getHeader();
    return await instance.post(baseURL + '/order/status/filter', data);
};

export const shipAllOrder = async (data) => {
    getHeader();
    return await instance.post(baseURL + '/order/ship/multi', data);
};
export const returnEntryAWB = async (data) => {
    getHeader();
    return await instance.post(baseURL + '/order/return/entry', data);
};

export const returnWrongEntry = async (data) => {
    getHeader();
    return await instance.post(baseURL + '/order/return/wrong', data);
};

export const printSlip = async (data) => {
    getHeader();
    return await instance.post(baseURL + '/order/print', data);
};

export const returnPayment = async (data) => {
    getHeader();
    return await instance.post(baseURL + '/order/airwaybill', data);
};

export const updateOrders = async (data) => {
    getHeader();
    return await instance.post(baseURL + '/order/update', data);
};

export const getDailySummryList = async (data) => {
    getHeader();
    return await instance.post(baseURL + '/report/daily', data);
};

export const exportList = async (data) => {
    getHeader();
    return await instance.post(baseURL + '/order/export', data);
};

export const advancedSummry = async (data) => {
    getHeader();
    return await instance.post(baseURL + '/report/advance', data);
};
