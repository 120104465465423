import React, { useRef, useState } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import { useForceUpdate } from '../../hooks/useForceUpdate';
import { returnWrongEntry } from '../../Services/service';
import { showToastError, showToastSuccess, } from '../../store/constant';
import Loader from '../../ui-component/Loader';

const WrongReturnEntry = () => {

    const forceUpdate = useForceUpdate();
    const validator = useRef(new SimpleReactValidator({ element: (message, className) => <div className={'u_error'}>{message}</div> }));
    const f1= useRef();
    const f2= useRef();
    const [allValues, setAllValues]= useState({});
    const [isLoader, setLoader]= useState(false);

    const change =(e, name)=>{
        setAllValues({...allValues, [name]: e, error : null})
    }

    const submitData =()=>{
        if(validator?.current?.allValid()){
            setLoader(true);
            let data= new FormData()
            data.set('awb', allValues?.awb);
           if(allValues?.podEntry) data.set('podEntry', allValues?.podEntry);
           if(allValues?.shipmentEntry) data.set('shipmentEntry', allValues?.shipmentEntry);
          
            returnWrongEntry(data)?.then((res) => {
                if (!res?.data?.error) {
                   setLoader(false)

                    showToastSuccess('Entry Succesfully.');
                }
                else {
                    setLoader(false)
                    validator?.current?.hideMessages();
                    setAllValues({...allValues, error: (res?.data?.data?.error || res?.data?.message)})
                    showToastError(res?.data?.data?.error || res?.data?.message)
                }
            })

        }else{
            validator?.current?.showMessages();
            forceUpdate();
        }
    }

    return (
        <React.Fragment>
          {isLoader &&  <Loader/>}
            <div className="row">
                <div className="col-lg-4">
                    <label className="w_label">Enter Airway Bill No.</label>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-4 col-md-6">
                    <div className="form-input">
                        <input type="text" placeholder="Name" value= {allValues?.awb} onChange={e=> change(e?.target?.value, 'awb')} className="form-control" />
                        <span className="w_error">{validator?.current?.message('awb', allValues?.awb, 'required')}</span>
                        <span className="w_error">{allValues?.error}</span>
                    </div>
                </div>
            </div>
            <div className="row w_mt-10">
                <div className="col-lg-4 col-md-6">
                    <label className="w_label">Upload POD Image</label>
                    <div className="form-input">
                        <div className="w_fileupload">
                            <input id="fileupload" ref={f1} onChange={e=> change(e?.target?.files[0], 'podEntry')} type="file" accept="image/*" />
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6">
                    <label className="w_label">Upload Shipment Image</label>
                    <div className="form-input">
                        <div className="w_fileupload">
                            <input id="fileupload"  ref={f2} type="file" accept="image/*" onChange={e=> change(e?.target?.files[0], 'shipmentEntry')}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row w_mt-10">
                {
                    allValues?.podEntry &&
                    <div className="col-lg-4 col-md-6">
                    <label className="w_label">Upload POD Image</label>
                    <div className="w_flex w_align-items-center w_logo-img">
                        <span className="w_img"><img src={allValues?.podEntry ? URL.createObjectURL(allValues?.podEntry) : ''}/></span>
                        <span className="w_logo-img-close" onClick={()=> {setAllValues({...allValues, podEntry: null }); if(f1?.current) f1.current.value=''}}>X</span>
                    </div>
                </div>
                }
               {
                   allValues?.shipmentEntry &&
                 <div className="col-lg-4 col-md-6">
                 <label className="w_label">Upload Shipment Image</label>
                 <div className="w_flex w_align-items-center w_logo-img">
                 <span className="w_img"><img src={allValues?.shipmentEntry ? URL.createObjectURL(allValues?.shipmentEntry) : ''}/></span>
                     <span className="w_logo-img-close" onClick={()=> {setAllValues({...allValues, shipmentEntry: null });  if(f2?.current) f2.current.value=''}}>X</span>
                 </div>
                </div>
               }
               
            </div>
            <div className="row w_mt-10">
                <div className="col-lg-4">
                    <button className="w-btn w-btn-blue" onClick={()=> submitData()}>Save</button>
                    <button className="w-btn w-btn-cancel w_ml-15" onClick={()=> setAllValues({})}>Cancel</button>
                </div>
            </div>
        </React.Fragment>
    );
};

export default WrongReturnEntry;
