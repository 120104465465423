import React, { useEffect, useRef, useState } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import { useForceUpdate } from '../../hooks/useForceUpdate';
import { returnEntryAWB } from '../../Services/service';
import { showToastError, showToastSuccess } from '../../store/constant';

const ReturnEntry = () => {
    const forceUpdate = useForceUpdate();
    const validator = useRef(new SimpleReactValidator({ element: (message, className) => <div className={'u_error'}>{message}</div> }));
    const [allValues, setAllValues] = useState({});
    const handle = {
        change: (e, name) => {
            setAllValues({ ...allValues, [name]: e, error: null });
        }
    };

    useEffect(() => {
        ref?.current?.focus();
    }, []);

    const ref = useRef();

    const submitRecord = (e) => {
        e?.preventDefault();
        if (validator?.current?.allValid()) {
            returnEntryAWB({ awb: allValues?.awb })?.then((res) => {
                if (!res?.data?.error) {
                    showToastSuccess('Entry Succesfully.');
                    setAllValues({ ...allValues, awb: '' });
                } else {
                    setAllValues({ ...allValues, awb: '', error: res?.data?.data?.error || res?.data?.message });
                    validator?.current?.hideMessages();
                    showToastError(res?.data?.data?.error || res?.data?.message);
                    ref?.current?.focus();
                }
                if (ref?.current?.value) ref.current.value = '';
            });
        } else {
            validator?.current?.showMessages();
            forceUpdate();
        }
    };
    return (
        <React.Fragment>
            <div>
                <form onSubmit={(e) => submitRecord(e)}>
                    <div className="row">
                        <div className="col-lg-4">
                            <label className="w_label">Enter Airway Bill</label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="form-input">
                                <input
                                    id="fileupload"
                                    value={allValues?.awb}
                                    className={`form-control`}
                                    type="text"
                                    ref={ref}
                                    onChange={(e) => handle?.change(e?.target?.value, 'awb')}
                                />
                                <span className="w_error">{validator?.current?.message('awb', allValues?.awb, 'required')}</span>
                                <span className="w_error">{allValues?.error}</span>
                            </div>
                        </div>
                    </div>

                    <div className="row col-lg-4 w_mt-20">
                        <button type="submit" class="w-btn w-btn-blue">
                            Save
                        </button>
                        <button className="w-btn w-btn-cancel w_ml-15" onClick={() => setAllValues({ ...allValues, awb: '' })}>
                            Cancel
                        </button>
                    </div>
                </form>
            </div>
        </React.Fragment>
    );
};

export default ReturnEntry;
