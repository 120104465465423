import React, { useEffect, useRef, useState } from 'react';

// project imports
import MainCard from '../../ui-component/cards/MainCard';
import Select from 'react-select';
import { accountList, addWebsite, deleteWebsite, getAccountList, getWebsite, updateWebsite } from '../../Services/service';
import { useForceUpdate } from '../../hooks/useForceUpdate';
import SimpleReactValidator from 'simple-react-validator';
import { showToastError, showToastSuccess } from '../../store/constant';

const aggregatorOptions = [
    { value: '1', label: 'Picker' },
    { value: '2', label: 'Ship Rokect' }
];

const selectTemplateOptions = [
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' }
];

// type: {
//     PINCODE: 1,
//     MANUAL: 2,
// },

const AddWebsite = () => {
    const forceUpdate = useForceUpdate();
    const validator = useRef(new SimpleReactValidator({ element: (message, className) => <div className={'u_error'}>{message}</div> }));
    const [list, setList] = useState([]);
    const [account, setAccount] = useState([]);
    const [allValues, setAllValues] = useState({
        name: '',
        description: '',
        aggregator: { value: '1', label: 'Picker' },
        accountId: '',
        activeId: '',
        template: { value: '1', label: '1' },
        type: 1,
        logo: '',
        email: '',
        from_phone_number: '',
        slip_phone_number: ''
    });
    const [error, setError] = useState('');
    let fileRef = useRef();
    const handle = (e, name) => {
        setAllValues({ ...allValues, [name]: e });

        if ((name === 'email' || name === 'from_phone_number') && e?.length > 0) {
            setError('');
        }
    };

    useEffect(() => {
        getList();
    }, []);

    useEffect(() => {
        getAccount();
    }, [allValues?.aggregator]);

    const getList = () => {
        getWebsite()?.then((res) => {
            if (!res?.data?.error) {
                setList(res?.data?.data?.rows);
            } else {
                showToastError(res?.data?.data?.error || res?.data?.message);
            }
        });
    };
    const getAccount = () => {
        getAccountList({ type: allValues?.aggregator?.value })?.then((res) => {
            if (!res?.data?.error) {
                let item = [];
                res?.data?.data?.rows?.map((e) => {
                    item?.push({ value: e?.id, label: e?.name });
                });
                setAccount(item);
            } else {
                showToastError(res?.data?.data?.error || res?.data?.message);
            }
        });
    };

    const editItem = (e) => {
        setAllValues({
            ...allValues,
            name: e?.name,
            aggregator: aggregatorOptions?.find((_) => _?.value == e?.aggregator),
            accountId: { label: e?.accountId?.name, value: e?.accountId?.id },
            activeId: e?.id,
            template: selectTemplateOptions?.find((_) => _?.value == e?.template),
            type: e?.type,
            description: e?.description,
            pickup_address: e?.pickup_address,
            from_phone_number: e?.from_phone_number,
            from_address: e?.from_address,
            from_pincode: e?.from_pincode,
            email: e?.email,
            slip_phone_number: e?.slip_phone_number,
            shopify_token: e?.shopify_token
        });
    };

    const deleteItem = (item) => {
        let param = {
            id: item?.id
        };
        deleteWebsite(param).then((res) => {
            if (!res?.data?.error) {
                clear();
                getList();
                showToastSuccess('Website Deleted.');
            } else {
                showToastError(res?.data?.data?.error || res?.data?.message);
            }
        });
    };

    const clear = () => {
        validator?.current?.hideMessages();
        setAllValues({
            ...allValues,
            name: '',
            aggregator: { value: '1', label: 'Picker' },
            accountId: '',
            activeId: '',
            template: { value: '1', label: '1' },
            type: 1,
            logo: '',
            description: '',
            pickup_address: '',
            from_phone_number: '',
            from_address: '',
            from_pincode: '',
            email: '',
            slip_phone_number: '',
            shopify_token: ''
        });
    };

    const submit = () => {
        if (!allValues.email && !allValues.from_phone_number) {
            setError('Email or Phone number must be required');
        } else {
            if (validator?.current?.allValid()) {
                setError('');
                let fd = new FormData();
                fd.append('name', allValues?.name);
                fd.append('description', allValues?.description);
                fd.append('pickup_address', allValues?.pickup_address);
                fd.append('template', allValues?.template?.value);
                fd.append('from_phone_number', allValues?.from_phone_number);
                fd.append('from_address', allValues?.from_address);
                fd.append('from_pincode', allValues?.from_pincode);
                fd.append('email', allValues?.email);
                fd.append('slip_phone_number', allValues?.slip_phone_number);
                fd.append('shopify_token', allValues?.shopify_token);
                if (allValues?.logo) {
                    fd.append('logo', allValues?.logo);
                }
                if (allValues?.activeId) {
                    fd.append('id', allValues?.activeId);
                    updateWebsite(fd).then((res) => {
                        if (!res?.data?.error) {
                            clear();
                            getList();
                            showToastSuccess('Website Updated.');
                        } else {
                            showToastError(res?.data?.data?.error || res?.data?.message);
                        }
                    });
                } else {
                    addWebsite(fd).then((res) => {
                        if (!res?.data?.error) {
                            clear();
                            getList();
                            showToastSuccess(res?.data?.data?.message);
                        } else {
                            showToastError(res?.data?.data?.error || res?.data?.message);
                        }
                    });
                }
            } else {
                validator?.current?.showMessages();
                forceUpdate();
            }
        }
    };

    const clearLogo = () => {
        if (fileRef?.current) fileRef.current.value = '';
        setAllValues({ ...allValues, logo: '' });
    };

    return (
        <MainCard title="Add Website">
            <div className="row">
                <div className="col-lg-4 col-md-6 w_mb-30">
                    <label className="w_label">Add website name</label>
                    <div className="form-input">
                        <input
                            type="text"
                            placeholder="Name"
                            value={allValues?.name}
                            onChange={(e) => handle(e?.target?.value, 'name')}
                            className="form-control"
                        />
                        <span className="w_error">{validator?.current?.message('name', allValues?.name, 'required')}</span>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 w_mb-30">
                    <label className="w_label">Description</label>
                    <div className="form-input">
                        <input
                            type="text"
                            placeholder="Description"
                            value={allValues?.description}
                            onChange={(e) => handle(e?.target?.value, 'description')}
                            className="form-control"
                        />
                        <span className="w_error">{validator?.current?.message('description', allValues?.description, 'required')}</span>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 w_mb-30">
                    <label className="w_label">Select logo image (200px Width*150px Height)</label>
                    <div className="form-input">
                        <div className="w_fileupload">
                            <input
                                ref={fileRef}
                                id="fileupload"
                                type="file"
                                onChange={(e) => handle(e?.target?.files[0], 'logo')}
                                accept="image/*"
                            />
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 w_mb-30">
                    {allValues?.logo?.name && <label className="w_label">Remove logo</label>}
                    {allValues?.logo?.name && (
                        <div className="w_flex w_align-items-center w_logo-img">
                            <span className="w_img">{allValues?.logo?.name}</span>
                            <span className="w_logo-img-close" onClick={() => clearLogo()}>
                                X
                            </span>
                        </div>
                    )}
                </div>
            </div>

            <div className="row">
                <div className="col-lg-4 col-md-6 w_mb-30">
                    <label className="w_label">Select Template</label>
                    <div className="form-input">
                        <Select
                            className="w_select"
                            classNamePrefix="w_select"
                            isClearable={true}
                            isSearchable={true}
                            onChange={(e) => handle(e, 'template')}
                            name="color"
                            options={selectTemplateOptions}
                            maxMenuHeight={110}
                            value={allValues?.template}
                        />
                        <span className="w_error">{validator?.current?.message('template', allValues?.template, 'required')}</span>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 w_mb-30">
                    <label className="w_label">Pickup Address</label>
                    <div className="form-input">
                        <textarea
                            type="text"
                            //  placeholder="Name"
                            value={allValues?.pickup_address}
                            onChange={(e) => handle(e?.target?.value, 'pickup_address')}
                            className="form-control"
                        />
                        <span className="w_error">
                            {validator?.current?.message('pickup_address', allValues?.pickup_address, 'required')}
                        </span>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 w_mb-30">
                    <label className="w_label">Email</label>
                    <div className="form-input">
                        <input
                            type="text"
                            placeholder="Email"
                            value={allValues?.email}
                            onChange={(e) => handle(e?.target?.value, 'email')}
                            className="form-control"
                        />
                        <span className="w_error">{validator?.current?.message('email', allValues?.email, 'email')}</span>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-4 col-md-6 w_mb-30">
                    <label className="w_label">From Address</label>
                    <div className="form-input">
                        <textarea
                            type="text"
                            value={allValues?.from_address}
                            onChange={(e) => handle(e?.target?.value, 'from_address')}
                            className="form-control"
                        />
                        <span className="w_error">{validator?.current?.message('from_address', allValues?.from_address, 'required')}</span>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 w_mb-30">
                    <label className="w_label">From Pincode</label>
                    <div className="form-input">
                        <input
                            type="number"
                            placeholder="Pincode"
                            value={allValues?.from_pincode}
                            onChange={(e) => handle(e?.target?.value, 'from_pincode')}
                            className="form-control"
                        />
                        <span className="w_error">{validator?.current?.message('from_pincode', allValues?.from_pincode, 'required')}</span>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 w_mb-30">
                    <label className="w_label">From Phone Number</label>
                    <div className="form-input">
                        <input
                            type="number"
                            placeholder="Phone Number"
                            value={allValues?.from_phone_number}
                            onChange={(e) => handle(e?.target?.value, 'from_phone_number')}
                            className="form-control"
                        />
                        <span className="w_error">{error}</span>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-4 col-md-6 w_mb-30">
                    <label className="w_label">Slip Phone Number</label>
                    <div className="form-input">
                        <input
                            type="number"
                            placeholder="Slip Phone Number"
                            value={allValues?.slip_phone_number}
                            onChange={(e) => handle(e?.target?.value, 'slip_phone_number')}
                            className="form-control"
                        />
                        <span className="w_error">{error}</span>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 w_mb-30">
                    <label className="w_label">Shopify Access Token</label>
                    <div className="form-input">
                        <input
                            type="string"
                            placeholder="Enter Shopify Access Token"
                            value={allValues?.shopify_token}
                            onChange={(e) => handle(e?.target?.value, 'shopify_token')}
                            className="form-control"
                        />
                        <span className="w_error">{error}</span>
                    </div>
                </div>
            </div>
            <div className="row w_mb-20">
                <div className="col-lg-4">
                    <button className="w-btn w-btn-blue" onClick={() => submit()}>
                        Save
                    </button>
                    {allValues?.activeId && (
                        <button className="w-btn w-btn-cancel w_ml-15" onClick={() => clear()}>
                            Cancel
                        </button>
                    )}
                </div>
            </div>

            <div className="row w_mt-10">
                <div className="col-12">
                    <div className="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Description</th>
                                    <th>Pickup Address</th>
                                    <th>From Address</th>
                                    <th>From Pincode</th>
                                    <th>From Phone</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {list?.map((item) => {
                                    return (
                                        <tr>
                                            <td>{item?.name}</td>
                                            <td>{item?.description}</td>
                                            <td>{item?.pickup_address}</td>
                                            <td>{item?.from_address}</td>
                                            <td>{item?.from_phone_number}</td>
                                            <td>{item?.from_pincode}</td>
                                            <td>
                                                <button
                                                    className="w-table-button w-table-button-save w_mr-15"
                                                    onClick={() => editItem(item)}
                                                >
                                                    Edit
                                                </button>
                                                <button className="w-table-button w-table-button-danger" onClick={() => deleteItem(item)}>
                                                    Delete
                                                </button>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </MainCard>
    );
};

export default AddWebsite;
