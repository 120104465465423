// assets
import { IconBrandFramer, IconTypography, IconPalette, IconShadow, IconWindmill, IconLayoutGridAdd } from '@tabler/icons';

// constant
const icons = {
    IconTypography: IconTypography,
    IconPalette: IconPalette,
    IconShadow: IconShadow,
    IconWindmill: IconWindmill,
    IconBrandFramer: IconBrandFramer,
    IconLayoutGridAdd: IconLayoutGridAdd
};

//-----------------------|| UTILITIES MENU ITEMS ||-----------------------//

export const utilities = {
    id: 'utilities',
    title: '',
    type: 'group',
    children: [
        {
            id: 'util-typography',
            title: 'Add Website',
            type: 'item',
            url: '/add-website',
            icon: icons['IconTypography'],
            breadcrumbs: false
        },
        {
            id: 'util-color',
            title: 'Add Staff',
            type: 'item',
            url: '/add-staff',
            icon: icons['IconPalette'],
            breadcrumbs: false
        },
        {
            id: 'util-shadow',
            title: 'Upload CSV',
            type: 'item',
            url: '/upload-csv',
            icon: icons['IconShadow'],
            breadcrumbs: false
        },
        {
            id: 'icons',
            title: 'All Order',
            type: 'collapse',
            icon: icons['IconWindmill'],
            children: [
                {
                    id: 'tabler-icons',
                    title: 'All Order',
                    type: 'item',
                    url: '/orders/all-order/7',
                    breadcrumbs: false
                },
                {
                    id: 'material-icons',
                    title: 'Return Order Form',
                    type: 'item',
                    url: '/orders/return-order-form/1',
                    breadcrumbs: false
                },
                {
                    id: 'return-order-list',
                    title: 'Return Order List',
                    type: 'item',
                    url: '/orders/return-order-list/1',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'manage-order',
            title: 'Manage Orders',
            type: 'item',
            url: '/manage-order/1',
            icon: icons['IconShadow'],
            breadcrumbs: false
        },
        {
            id: 'return-payment',
            title: 'Payment Reconciliation',
            type: 'item',
            url: '/return-payment',
            icon: icons['IconShadow'],
            breadcrumbs: false
        },
        {
            id: 'settings-item',
            title: 'Settings',
            type: 'item',
            url: '/settings/1',
            icon: icons['IconShadow'],
            breadcrumbs: false
        },
        {
            id: 'report-item',
            title: 'Report',
            type: 'item',
            url: '/report/1',
            icon: icons['IconShadow'],
            breadcrumbs: false
        },
    ]
};
