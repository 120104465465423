import React, { useRef, useState } from 'react';
import Select from 'react-select';
import SimpleReactValidator from 'simple-react-validator';
import { useForceUpdate } from '../../hooks/useForceUpdate';
import { exportPincode, importPincode } from '../../Services/service';
import { baseURL, CourierOption, showToastError, showToastSuccess } from '../../store/constant';

const Pincode = () => {
    const forceUpdate = useForceUpdate();
    let fileRef = useRef();
    const validator = useRef(new SimpleReactValidator({ element: (message, className) => <div className={'u_error'}>{message}</div> }));
    const validatorI = useRef(new SimpleReactValidator({ element: (message, className) => <div className={'u_error'}>{message}</div> }));
    const [exportValue, setExportValue]=useState({
        courier:''
    })

    const [importValue, setImportValue]=useState({
        courier:'',
        file:''
    })

    const handle = (e, name) => {
        setExportValue({ ...exportValue, [name]: e })
    }
    
    const handleImport = (e, name) => { 
        setImportValue({ ...importValue, [name]: e })
    }

    const exportSubmit= () => {
        if(validator?.current?.allValid()){
            let param={courier : exportValue?.courier?.value}
            exportPincode(param).then(res => {
                if (!res?.data?.error) {
                    showToastSuccess('Exported successfully.')
                    window.open(`${baseURL}/${res?.data?.data?.file}`, '_self')
                }
                else {
                    showToastError(res?.data?.data?.error || res?.data?.message)
                }
            })
        }else{
            validator?.current?.showMessages();
            forceUpdate();
        }
    }

    const clearImport =()=>{
        if(fileRef?.current) fileRef.current.value=''
        setImportValue({...importValue, file:'', courier:''})
    }

    const importSubmit= () => {
        if(validatorI?.current?.allValid()){
            let data= new FormData();
            data.set('courier', importValue?.courier?.value)
            data.set('pincode', importValue?.file?.target?.files[0])
    
            importPincode(data).then(res => {
                if (!res?.data?.error) {
                    clearImport()
                    showToastSuccess('Import successfully.')
                }
                else {
                    showToastError(res?.data?.data?.error || res?.data?.message)
                }
            })
        }else{
            validatorI?.current?.showMessages();
            forceUpdate();
        }
    }

    return (
        <React.Fragment>
            <div className="row">
                <div className="col-12 w_mb-20">
                    <h2>Export Pincode File</h2>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-4 col-md-6 w_mb-30">
                    <label className="w_label">Select Courier Services</label>
                    <div className="form-input">
                        <Select
                            className="w_select"
                            classNamePrefix="w_select"
                            isClearable={true}
                            isSearchable={true}
                            onChange={(e) => handle(e, 'courier')}
                            name="color"
                            options={CourierOption}
                            maxMenuHeight={110}
                            value={exportValue?.courier}
                        />
                         <span className="w_error">{validator?.current?.message('courier', exportValue?.courier, 'required')}</span>
                    </div>
                </div>
             
                <div className="col-lg-4 col-md-6 w_mb-30">
                    <label className="w_label">Export Pincode File</label>
                    <button className="w-btn w-btn-blue w_100" onClick={()=>exportSubmit()}>Export Pincode File</button>
                </div>
            </div>
            <div className="row w_mb-20">
                <div className="col-12 ">
                    <h2>Upload Pincode File</h2>
                </div>
            </div>
            <div className="row w_mt-10">
                <div className="col-lg-4 col-md-6 w_mb-30">
                    <label className="w_label">Select Courier Services</label>
                    <div className="form-input">
                        <Select
                            className="w_select"
                            classNamePrefix="w_select"
                            isClearable={true}
                            isSearchable={true}
                            onChange={(e) => handleImport(e, 'courier')}
                            name="color"
                            options={CourierOption}
                            maxMenuHeight={110}
                            value={importValue?.courier}
                        />
                        <span className="w_error">{validatorI?.current?.message('courier', importValue?.courier, 'required')}</span>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 w_mb-30">
                    <label className="w_label">Select Pincode File</label>
                    <div className="form-input">
                        <div className="w_fileupload">
                            <input id="fileupload" ref={fileRef} onChange={(e)=>{handleImport(e, 'file')}} type="file" pattern="^.+\.(xlsx|xls)$" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,text/comma-separated-values" />
                            <span className="w_error">{validatorI?.current?.message('File', importValue?.file, 'required')}</span>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 w_mb-30">
                    <label className="w_label">Upload Pincode File</label>
                    <button className="w-btn w-btn-blue w_100" onClick={()=>importSubmit()}>Upload Pincode File</button>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Pincode;
