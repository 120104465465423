import React, { useRef, useState } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import { useForceUpdate } from '../../hooks/useForceUpdate';
import { advancedSummry } from '../../Services/service';
import { showToastError, showToastSuccess } from '../../store/constant';
import MainCard from '../../ui-component/cards/MainCard';
import Loader from '../../ui-component/Loader';

const AdvanceSummary = () => {
    let fileRef = useRef();
    const forceUpdate = useForceUpdate();
    const validator = useRef(new SimpleReactValidator({ element: (message, className) => <div className={'u_error'}>{message}</div> }));
    const [isLoader, setLoader]= useState(false);
    const [allValues, setAllValues]= useState({
        file:'',
        type:1
    })

    const handle = (e, name) => {
        setAllValues({ ...allValues, [name]: e })
    }

    const submit=()=>{
        
        if(validator?.current?.allValid()){
            setLoader(true);

            let data= new FormData();
            data.set('reportType', allValues?.type);
            data.set('airway', allValues?.file?.target?.files[0]);

            advancedSummry(data).then(res => {
                if (!res?.data?.error) {
                    setLoader(false);
                    res?.data?.data?.file?.map(e=>{
                        window.open(e, '_self')
                       })
                    cancelSave();
                    showToastSuccess('Exported successfully.');
                    validator?.current?.hideMessages();
                }
                else {
                    setLoader(false);
                    showToastError(res?.data?.data?.error || res?.data?.message)
                }
            })
        }else{
            validator?.current?.showMessages()
            forceUpdate();
        }
    }
    const cancelSave=()=>{
        if(fileRef?.current) fileRef.current.value=''
        validator?.current?.hideMessages();
        setAllValues({...allValues, file: '', type: 1});
    }
    return (
        <React.Fragment>
             {isLoader &&  <Loader/>}
            <MainCard title="Order information Export">
                <div className="row">
                    <div className="col-lg-4 col-md-6 w_mb-30">
                        <label className="w_label">Choose Excel file</label>
                        <div className="form-input">
                        <div className="w_fileupload">
                            <input id="fileupload" ref={fileRef} type="file" onChange={(e)=>handle(e, 'file')} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,text/comma-separated-values" placeholder="Choose Excel file" />
                            <span className="w_error">{validator?.current?.message('file', allValues?.file, 'required')}</span>
                        </div>
                        </div>
                    </div>{
                        allValues?.file?.target?.files?.[0] &&
                        <div className="col-lg-4 col-md-6 w_mb-30">
                        <label className="w_label">Excel remove</label>
                        <div className="w_flex w_align-items-center w_logo-img">
                            <span className="w_img">{allValues?.file?.target?.files[0]?.name}</span>
                            <span className="w_logo-img-close" onClick={()=>cancelSave()}>X</span>
                        </div>
                    </div>
                    }
                   
                </div>
                <div className="row">
                    <div className="col-lg-4 w_mb-30">
                        <label className="w_label">Select Order Info</label>
                        <div className="w_flex w_align-items-center">
                            <label className="w-radio">
                                Order information Export in Excel
                                <input
                                    type="radio"
                                    name="radio"
                                    checked={allValues?.type == 1}
                                    onChange={(e) => handle(e?.target?.checked ? 1 : 2, 'type')}
                                />
                                <span className="checkmark"></span>
                            </label>
                            <label className="w-radio">
                                Order Slip Export
                                <input
                                    type="radio"
                                    name="radio"
                                    checked={allValues?.type == 2}
                                    onChange={(e) => handle(e?.target?.checked ? 2 : 1, 'type')}
                                />
                                <span className="checkmark"></span>
                            </label>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4">
                        <button className="w-btn w-btn-blue" onClick={()=>submit()}>Export</button>
                    </div>
                </div>
            </MainCard>
        </React.Fragment>
    );
};
export default AdvanceSummary;
