import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import ReturnEntry from './return-entry';
import WrongReturnEntry from './wrong-return-entry';

const ReturnOrder = () => {
    const {id} = useParams();
    const history= useHistory()
    const [active,setActive]= useState(1);

    useEffect(() => {
        setActive(parseInt(id));
    }, [id]);
    return (
        <React.Fragment>
            <div className="white_bg w_pl-20 w_pr-20 w_pt-20 w_pb-0 css-11bu8qf-MuiPaper-root-MuiCard-root">
                <div className="row">
                    <div className="w_tabling">
                        <ul>
                            <li  onClick={()=>history?.push('/orders/return-order-form/1')} className={`${active === 1 ? 'w_active' : ''}`}>Return Received</li>
                            <li  onClick={()=>history?.push('/orders/return-order-form/2')} className={`${active === 2 ? 'w_active' : ''}`}>Wrong Return Received</li>
                        </ul>
                    </div>
                </div>
                {active === 1 && <ReturnEntry/>}
                {active === 2 && <WrongReturnEntry/>}
            </div>
        </React.Fragment>
    );
};

export default ReturnOrder;
