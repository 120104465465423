import React from 'react';

const shortAddress =React.memo(({settings, onChange, updateSettings}) => {
    return (
        <React.Fragment>
            {/* Short Address */}
            <div className="row">
                <div className="col-lg-4 col-md-6 w_mb-30">
                    <label className="w_label">Character Limit</label>
                    <div className="form-input">
                        <input type="number" value={settings?.short_address} step={1} onChange={(e)=>onChange('short_address', e?.target?.value)} placeholder="Character Limit" className="form-control" />
                    </div>
                </div>
            </div>
            <div className="row w_mb-20">
                <div className="col-lg-4">
                    <button className="w-btn w-btn-blue" onClick={()=>updateSettings()}>save</button>
                    {/* <button className="w-btn w-btn-cancel w_ml-15">Cancel</button> */}
                </div>
            </div>
        </React.Fragment>
    );
});

export default shortAddress;
