import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { getMasterSettings, updateMasterSettings } from '../../Services/service';
import { showToastError, showToastSuccess } from '../../store/constant';
import AddAccount from './add-account';
import BigAmount from './bigAmount';
import Pincode from './pincode';
import ShortAddress from './shortAddress';
import Template from './template';

const Setting = () => {
    const {id} = useParams();
    const history= useHistory()
    const [active,setActive]= useState(1);
    const [settings, setSettings] = useState({
        big_amount:0,
        short_address:0,
        big_amountC:0,
        short_addressC:0
    })

    useEffect(()=>{
        setActive(parseInt(id))
        setSettings({...settings, big_amount: settings?.big_amountC,short_address: settings?.short_addressC })
    },[id])

    useEffect(()=>{
        getMasterSettings()?.then((res) => {
            if (!res?.data?.error) {
                setSettings({big_amount :res?.data?.data?.rows[0]?.value, short_address :res?.data?.data?.rows[1]?.value});
            }
            else {
                showToastError(res?.data?.message)
            }
        })
    },[])

    const onChange =(name, value)=>{
        setSettings({...settings, [name] : value})
    }

    const updateSettings =()=>{
        updateMasterSettings({big_amount :settings?.big_amount,short_address: settings?.short_address})?.then((res) => {
            if (!res?.data?.error) {
               showToastSuccess('Settings Updated');
               setSettings({...settings, big_amountC: settings?.big_amount,short_addressC: settings?.short_address })
            }
            else {
                showToastError(res?.data?.message)
            }
        })
    }

    return (
        <React.Fragment>
            <div className="white_bg w_pl-20 w_pr-20 w_pt-20 w_pb-0 css-11bu8qf-MuiPaper-root-MuiCard-root">
                <div className="row">
                    <div className="w_tabling">
                        <ul>
                            <li onClick={()=>history?.push('/settings/1')} className={`${active === 1 ? 'w_active' : ''}`}>Add Account</li>
                            <li  onClick={()=>history?.push('/settings/2')} className={`${active === 2 ? 'w_active' : ''}`}>Short Address</li>
                            <li  onClick={()=>history?.push('/settings/3')} className={`${active === 3 ? 'w_active' : ''}`}>Big Amount</li>
                            <li  onClick={()=>history?.push('/settings/4')} className={`${active === 4 ? 'w_active' : ''}`}>Templete</li>
                            <li  onClick={()=>history?.push('/settings/5')} className={`${active === 5 ? 'w_active' : ''}`}>Pincode</li>
                        </ul>
                    </div>
                </div>
                {active === 1 && <AddAccount/>}
                {active === 2 && <ShortAddress settings={settings} onChange={(name, value)=>onChange(name, value)} updateSettings ={updateSettings}/>}
                {active === 3 && <BigAmount settings={settings}  onChange={(name, value)=>onChange(name , value)} updateSettings ={updateSettings}/> }
                {active === 4 && <Template/>}
                {active === 5 && <Pincode/>}
                
            </div>
        </React.Fragment>
    );
};

export default Setting;
