import React, { useEffect, useRef, useState } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import { useForceUpdate } from '../../hooks/useForceUpdate';
import { returnPayment } from '../../Services/service';
import { showToastError, showToastSuccess } from '../../store/constant';

// project imports
import MainCard from '../../ui-component/cards/MainCard';

const ReturnPayment = () => {
    const forceUpdate = useForceUpdate();
    let fileRef = useRef();
    const validator = useRef(new SimpleReactValidator({ element: (message, className) => <div className={'u_error'}>{message}</div> }));
    const [importValue, setImportValue]=useState({
        courier:'',
        file:'',
    })
    const [details, setDetails]= useState('')

    const handleImport = (e, name) => { 
        setImportValue({ ...importValue, [name]: e })
    }

    const clearImport =()=>{
        if(fileRef?.current) fileRef.current.value=''
        setImportValue({...importValue, file:'', courier:''})
    }

    const importSubmit= () => {
        if(validator?.current?.allValid()){
            let data= new FormData();
          //  data.set('courier', importValue?.courier?.value)
            data.set('airway', importValue?.file?.target?.files[0])
    
            returnPayment(data).then(res => {
                if (!res?.data?.error) {
                    setDetails(res?.data?.data)
                    clearImport()
                    showToastSuccess('Import successfully.')
                }
                else {
                    showToastError(res?.data?.data?.error || res?.data?.message)
                }
            })
        }else{
            validator?.current?.showMessages();
            forceUpdate();
        }
    }
    console.log(details)
    return (
        <MainCard title="Payment Reconciliation">
            <div className="row">
                <div className="col-lg-4 col-md-6 w_mb-30">
                    <label className="w_label">Choose file</label>
                    <div className="form-input">
                        <div className="w_fileupload">
                        <div className="w_fileupload">
                            <input id="fileupload" ref={fileRef} onChange={(e)=>{handleImport(e, 'file')}} type="file" pattern="^.+\.(xlsx|xls)$" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,text/comma-separated-values" />
                            <span className="w_error">{validator?.current?.message('File', importValue?.file, 'required')}</span>
                        </div>
                        </div>
                    </div>
                </div>
                {
                  importValue?.file?.target?.files[0] &&   <div className="col-lg-4 col-md-6 w_mb-30">
                     <div className="w_flex w_align-items-center w_logo-img">
                        <span className="w_img">{importValue?.file?.target?.files[0]?.name}</span>
                         <span className="w_logo-img-close" onClick={()=>clearImport()}>X</span>
                     </div>
                 </div>
                }
               
            </div>
            <div className="row">
                <div className="col-lg-6 w_ml-15">
                    <button className="w-btn w-btn-blue" onClick={()=>importSubmit()}>Submit</button>
                    {details?.paid?.length && <button className="w-btn w-btn-green w_ml-15">{details?.paid?.length || 0}</button>}
                    {details?.failed?.length && <button className="w-btn w-btn-danger w_ml-15">{details?.failed?.length || 0}</button>}
                </div>
            </div>
            <div className="row w_mt-10">
                <div className="col-12">
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Airway bill No.</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    details?.paid?.map(e=>{
                                        return(
                                            <tr>
                                            <td>
                                                <div className="w_flex w_align-items-center w_mb-5">
                                                    <span className="w-color-blue w_mr-5 flex-shrink">Airway bill No. : </span> {e}</div>
                                                    </td>
                                            <td>
                                                <button className="w-table-button w-table-button-green">Paid</button>
                                            </td>
                                        </tr>
                                        )
                                    })
                                 }
                                 {
                                    details?.failed?.map(e=>{
                                        return(
                                            <tr>
                                            <td>
                                                <div className="w_flex w_align-items-center w_mb-5">
                                                    <span className="w-color-blue w_mr-5 flex-shrink">Airway bill No. : </span> {e}</div>
                                                    </td>
                                            <td>
                                                <button className="w-table-button w-table-button-danger">Failed</button>
                                            </td>
                                        </tr>
                                        )
                                    })
                                      
                                }
                              
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </MainCard>
    );
};

export default ReturnPayment;
