import React, { useEffect, useRef, useState } from 'react';

// material-ui
import { Typography } from '@material-ui/core';

// project imports
import MainCard from '../../ui-component/cards/MainCard';
import { Input } from '@material-ui/core';
import Select from 'react-select';
import { showToastError, showToastSuccess } from '../../store/constant';
import { getAllShopifyOrder, getSingleShopifyOrder, uploadCSV, uploadShopifyCSV, webSiteList } from '../../Services/service';
import { Clear } from '@material-ui/icons';
import { useForceUpdate } from '../../hooks/useForceUpdate';
import SideModal from '../components/SideModal';
import SimpleReactValidator from 'simple-react-validator';
import Loader from '../../ui-component/Loader';

//==============================|| SAMPLE PAGE ||==============================//

const UploadCsv = () => {
    let fileRef = useRef();
    let shopifyFileRef = useRef();
    const forceUpdate = useForceUpdate();
    const validator = useRef(new SimpleReactValidator({ element: (message, className) => <div className={'u_error'}>{message}</div> }));
    const allValidator = useRef(new SimpleReactValidator({ element: (message, className) => <div className={'u_error'}>{message}</div> }));
    const singleValidator = useRef(
        new SimpleReactValidator({ element: (message, className) => <div className={'u_error'}>{message}</div> })
    );
    const [option, setOption] = useState([]);
    const [allValues, setAllValues] = useState({
        file: '',
        website: ''
    });

    const [allValuesForShopify, setAllValuesForShopify] = useState({
        file: '',
        website: ''
    });

    const [open, setOpen] = useState(false);
    const [resData, setResData] = useState({});
    const [isLoader, setIsLoader] = useState(false);

    const [fetchAll, setFetchAll] = useState({
        website: ''
    });

    const [fetchSingle, setFetchSingle] = useState({
        website: '',
        orderId: ''
    });

    const handle = (e, name, isModified) => {
        if (isModified) {
            setAllValues({ ...allValues, [name]: e });
        } else {
            setAllValuesForShopify({ ...allValuesForShopify, [name]: e });
        }
    };

    const handleFetchAll = (e) => {
        setFetchAll({ website: e });
    };

    const handleFetchSingle = (e, name) => {
        setFetchSingle({ ...fetchSingle, [name]: e });
    };

    useEffect(() => {
        webSiteList()?.then((res) => {
            if (!res?.data?.error) {
                let item = [];
                res?.data?.data?.map((e) => {
                    item?.push({ value: e?.id, label: e?.name });
                });
                setOption(item);
            } else {
                showToastError(res?.data?.message);
            }
        });
    }, []);

    const submit = () => {
        if (validator?.current?.allValid()) {
            let data = new FormData();
            data.set('websiteId', allValues?.website?.value);
            data.set('order', allValues?.file?.target?.files[0]);

            uploadCSV(data).then((res) => {
                if (!res?.data?.error) {
                    cancelSave();
                    showToastSuccess('Uploaded successfully.');
                    validator?.current?.hideMessages();
                } else {
                    showToastError(res?.data?.data?.error || res?.data?.message);
                }
            });
        } else {
            validator?.current?.showMessages();
            forceUpdate();
        }
    };

    const submitShopify = () => {
        if (validator?.current?.allValid()) {
            let data = new FormData();
            data.set('websiteId', allValuesForShopify?.website?.value);
            data.set('order', allValuesForShopify?.file?.target?.files[0]);

            uploadShopifyCSV(data).then((res) => {
                if (!res?.data?.error) {
                    cancelShopifySave();
                    showToastSuccess('Shopify Sheet Uploaded successfully.');
                    validator?.current?.hideMessages();
                } else {
                    showToastError(res?.data?.data?.error || res?.data?.message);
                }
            });
        } else {
            validator?.current?.showMessages();
            forceUpdate();
        }
    };

    const submitGetAll = () => {
        if (allValidator?.current?.allValid()) {
            setIsLoader(true);
            let allFD = {
                website: fetchAll?.website?.value
            };

            getAllShopifyOrder(allFD).then((res) => {
                if (res?.status === 200) {
                    setIsLoader(false);
                    cancelSave();
                    setResData({ ...res?.data?.data });
                    setOpen(true);
                    allValidator?.current?.hideMessages();
                } else {
                    setIsLoader(false);
                    showToastError('Technical Error Occurred');
                }
            });
        } else {
            allValidator?.current?.showMessages();
            forceUpdate();
        }
    };

    const submitGetSingle = () => {
        if (singleValidator?.current?.allValid()) {
            setIsLoader(true);
            let singleFD = {
                website: fetchSingle?.website?.value,
                orderId: fetchSingle?.orderId
            };

            getSingleShopifyOrder(singleFD).then((res) => {
                if (res?.status === 200) {
                    setIsLoader(false);
                    cancelSave();
                    setResData({ ...res?.data?.data });
                    setOpen(true);
                    allValidator?.current?.hideMessages();
                } else {
                    setIsLoader(false);
                    showToastError('Technical Error Occurred');
                }
            });
        } else {
            singleValidator?.current?.showMessages();
            forceUpdate();
        }
    };

    const cancelSave = () => {
        if (fileRef?.current) fileRef.current.value = '';
        setAllValues({ ...allValues, file: '', website: '' });
        setFetchAll({ website: '' });
        setFetchSingle({ website: '', orderId: '' });
    };

    const cancelShopifySave = () => {
        if (shopifyFileRef?.current) shopifyFileRef.current.value = '';
        setAllValuesForShopify({ ...allValuesForShopify, file: '', website: '' });
        setFetchAll({ website: '' });
        setFetchSingle({ website: '', orderId: '' });
    };

    return (
        <>
            {isLoader && <Loader />}
            <MainCard title="Excel File">
                <div className="row">
                    <div className="col-lg-4 col-md-6 w_mb-30">
                        <label className="w_label">Choose Excel file</label>
                        <div className="form-input">
                            <div className="w_fileupload">
                                <input
                                    id="fileupload"
                                    ref={fileRef}
                                    type="file"
                                    onChange={(e) => handle(e, 'file', true)}
                                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,text/comma-separated-values"
                                    placeholder="Choose Excel file"
                                />
                                <span className="w_error">{validator?.current?.message('file', allValues?.file, 'required')}</span>
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-lg-4 col-md-6 w_mb-30">
                    <label className="w_label">logo image remove</label>
                    <div className="w_flex w_align-items-center w_logo-img">
                        <span className="w_img">abc</span>
                        <span className="w_logo-img-close">X</span>
                    </div>
                </div> */}
                    <div className="col-lg-4 col-md-6 w_mb-30">
                        <label className="w_label">Select website list</label>
                        <div className="form-input">
                            <Select
                                className="w_select"
                                placeholder="Select website list"
                                classNamePrefix="w_select"
                                isClearable={true}
                                isSearchable={true}
                                value={allValues?.website}
                                name="color"
                                onChange={(e) => handle(e, 'website', true)}
                                options={option}
                                maxMenuHeight={110}
                            />
                            <span className="w_error">{validator?.current?.message('website', allValues?.website, 'required')}</span>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4">
                        <button className="w-btn w-btn-blue" onClick={() => submit()}>
                            Save
                        </button>
                        <button className="w-btn w-btn-cancel w_ml-15" onClick={() => cancelSave()}>
                            Cancel
                        </button>
                    </div>
                </div>

                <hr />

                {/* // upload sheet from Shopify */}
                <div className="row">
                    <div className="col-lg-4 col-md-6 w_mb-30">
                        <label className="w_label">Choose CSV file from Shopify</label>
                        <div className="form-input">
                            <div className="w_fileupload">
                                <input
                                    id="fileuploadcsv"
                                    ref={shopifyFileRef}
                                    type="file"
                                    onChange={(e) => handle(e, 'file', false)}
                                    accept=".csv"
                                    placeholder="Choose Excel file"
                                />
                                <span className="w_error">
                                    {validator?.current?.message('file', allValuesForShopify?.file, 'required')}
                                </span>
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-lg-4 col-md-6 w_mb-30">
                    <label className="w_label">logo image remove</label>
                    <div className="w_flex w_align-items-center w_logo-img">
                        <span className="w_img">abc</span>
                        <span className="w_logo-img-close">X</span>
                    </div>
                </div> */}
                    <div className="col-lg-4 col-md-6 w_mb-30">
                        <label className="w_label">Select website list</label>
                        <div className="form-input">
                            <Select
                                className="w_select"
                                placeholder="Select website list"
                                classNamePrefix="w_select"
                                isClearable={true}
                                isSearchable={true}
                                value={allValuesForShopify?.website}
                                name="color"
                                onChange={(e) => handle(e, 'website', false)}
                                options={option}
                                maxMenuHeight={110}
                            />
                            <span className="w_error">
                                {validator?.current?.message('website', allValuesForShopify?.website, 'required')}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4">
                        <button className="w-btn w-btn-blue" onClick={() => submitShopify()}>
                            Save
                        </button>
                        <button className="w-btn w-btn-cancel w_ml-15" onClick={() => cancelShopifySave()}>
                            Cancel
                        </button>
                    </div>
                </div>

                <hr />

                {/* Fetch from Shopify */}
                <div className="row w_mt-30">
                    {/* All Order */}
                    <div className="col-lg-6">
                        <div className="card-round">
                            <div className="row w_content-center">
                                <h2>All Order (unfulfilled)</h2>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <label className="w_label">Select website</label>
                                    <div className="form-input">
                                        <Select
                                            className="w_select"
                                            placeholder="Select website list"
                                            classNamePrefix="w_select"
                                            isClearable={true}
                                            isSearchable={true}
                                            value={fetchAll?.website}
                                            name="fetchAll"
                                            onChange={(e) => handleFetchAll(e)}
                                            options={option}
                                            maxMenuHeight={110}
                                        />
                                        <span className="w_error">
                                            {allValidator?.current?.message('website', fetchAll?.website, 'required')}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="row w_content-center w_pt-15">
                                <button className="w-btn w-btn-success" onClick={submitGetAll}>
                                    Add All
                                </button>
                            </div>
                        </div>
                    </div>
                    {/* Single Order */}
                    <div className="col-lg-6">
                        <div className="card-round">
                            <div className="row w_content-center">
                                <h2>Single Order (unfulfilled)</h2>
                            </div>
                            <div className="row">
                                <div className="col-12 w_mb-15">
                                    <label className="w_label">Select website</label>
                                    <div className="form-input">
                                        <Select
                                            className="w_select"
                                            placeholder="Select website list"
                                            classNamePrefix="w_select"
                                            isClearable={true}
                                            isSearchable={true}
                                            value={fetchSingle?.website}
                                            name="fetchSingle"
                                            onChange={(e) => handleFetchSingle(e, 'website')}
                                            options={option}
                                            maxMenuHeight={110}
                                        />
                                        <span className="w_error">
                                            {singleValidator?.current?.message('website', fetchSingle?.website, 'required')}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <label className="w_label">Shopify OrderID</label>
                                    <div className="form-input">
                                        <input
                                            type="text"
                                            placeholder="Shopify Order ID"
                                            value={fetchSingle?.orderId}
                                            onChange={(e) => handleFetchSingle(e?.target?.value, 'orderId')}
                                            className="form-control"
                                        />
                                        <span className="w_error">
                                            {singleValidator?.current?.message('Order ID', fetchSingle?.orderId, 'required')}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="row w_content-center w_pt-15">
                                <button className="w-btn w-btn-success" onClick={submitGetSingle}>
                                    Add Single
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </MainCard>
            {open && (
                <SideModal
                    open={open}
                    name={'Status'}
                    maxWidth={800}
                    onClose={() => {
                        setOpen(false);
                        setResData({});
                    }}
                    header={<h4>{`Success: ${resData?.total || 0}, Failed: ${resData?.skipped || 0}`}</h4>}
                    footer={
                        <div className="row w_pl-20 w_pr-20">
                            <div className="u_col-3">
                                <button
                                    className={`w-btn w-btn-danger w_ml-20`}
                                    onClick={() => {
                                        setOpen(false);
                                        setResData({});
                                    }}
                                >
                                    Close
                                </button>
                            </div>
                            <div className="u_col-6"></div>
                        </div>
                    }
                >
                    <div className="w_pl-20 w_pt-20 w_pb-20 w_pr-20">
                        {resData?.error?.length >= 0 ? (
                            <>
                                {resData?.error?.map((e) => {
                                    if (typeof e === 'string') {
                                        return (
                                            <>
                                                <span>{e}</span> <br />
                                            </>
                                        );
                                    }
                                    if (typeof e === 'object') {
                                        return (
                                            <>
                                                <span>{JSON.stringify(e)}</span>
                                                <br />
                                            </>
                                        );
                                    }
                                })}
                            </>
                        ) : (
                            <></>
                        )}
                    </div>
                </SideModal>
            )}
        </>
    );
};

export default UploadCsv;
