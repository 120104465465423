import React, { useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import SimpleReactValidator from 'simple-react-validator';
import { useForceUpdate } from '../../hooks/useForceUpdate';
import { accountList, addAccount, deleteAccount, updateAccount } from '../../Services/service';
import { showToastError, showToastSuccess } from '../../store/constant';

const aggregatorOptions = [
    { value: 1, label: 'Picker' },
    { value: 2, label: 'Ship Rokect' },
    { value: 3, label: 'Blue Dart' },
    { value: 4, label: 'Delhivery' },
    { value: 5, label: 'iThink' },
    { value: 6, label: 'ParcelX' }
];

const AddAccount = () => {
    const forceUpdate = useForceUpdate();
    const validator = useRef(new SimpleReactValidator({ element: (message, className) => <div className={'u_error'}>{message}</div> }));
    const [list, setList] = useState([]);
    const [allValues, setAllValues] = useState({
        type: '',
        name: '',
        email: '',
        token: '',
        activeId: '',
        shippingEmail: '',
        shippingPassword: '',
        customerCode: '',
        loginID: '',
        area: '',
        licenceKey: '',
        trackingLicenceKey: '',
        pickupAddressID: ''
    });

    const handle = (e, name) => {
        setAllValues({ ...allValues, [name]: e });
    };

    useEffect(() => {
        getAccount();
    }, []);

    const getAccount = () => {
        accountList()?.then((res) => {
            if (!res?.data?.error) {
                setList(res?.data?.data?.rows);
            } else {
                showToastError(res?.data?.message);
            }
        });
    };

    const deleteItem = (id) => {
        deleteAccount(id).then((res) => {
            if (!res?.data?.error) {
                clear();
                getAccount();
                showToastSuccess('Account Deleted.');
            } else {
                showToastError(res?.data?.data?.error || res?.data?.message);
            }
        });
    };

    const clear = () => {
        setAllValues({
            ...allValues,
            type: '',
            name: '',
            email: '',
            token: '',
            activeId: '',
            shippingEmail: '',
            shippingPassword: '',
            customerCode: '',
            loginID: '',
            area: '',
            licenceKey: '',
            trackingLicenceKey: '',
            pickupAddressID: ''
        });
        validator?.current?.hideMessages();
    };

    const editItem = (e) => {
        setAllValues({
            ...allValues,
            type: aggregatorOptions?.find((_) => _?.value == e?.type),
            name: e?.name,
            email: e?.email,
            token: e?.token,
            activeId: e?.id,
            shippingEmail: e?.shippingEmail,
            shippingPassword: e?.shippingPassword,
            customerCode: e?.customerCode,
            loginID: e?.loginID,
            area: e?.area,
            licenceKey: e?.licenceKey,
            trackingLicenceKey: e?.trackingLicenceKey,
            pickupAddressID: e?.pickupAddressID
        });
    };

    const submit = () => {
        if (validator?.current?.allValid()) {
            let param = {
                name: allValues?.name,
                type: allValues?.type?.value,
                email: allValues?.email,
                token: allValues?.token,
                shippingEmail: allValues?.shippingEmail,
                shippingPassword: allValues?.shippingPassword,
                customerCode: allValues?.customerCode,
                loginID: allValues?.loginID,
                area: allValues?.area,
                licenceKey: allValues?.licenceKey,
                trackingLicenceKey: allValues?.trackingLicenceKey,
                pickupAddressID: allValues?.pickupAddressID
            };
            console.log('🚀 ~ submit ~ param.allValues:', allValues);
            if (allValues?.activeId) {
                param.id = allValues?.activeId;
                updateAccount(param).then((res) => {
                    if (!res?.data?.error) {
                        clear();
                        getAccount();
                        showToastSuccess('Account Updated.');
                    } else {
                        showToastError(res?.data?.data?.error || res?.data?.message);
                    }
                });
            } else {
                addAccount(param).then((res) => {
                    if (!res?.data?.error) {
                        clear();
                        getAccount();
                        showToastSuccess('Account Created.');
                    } else {
                        showToastError(res?.data?.data?.error || res?.data?.message);
                    }
                });
            }
        } else {
            validator?.current?.showMessages();
            forceUpdate();
        }
    };
    return (
        <React.Fragment>
            {/* Add Account */}
            <div className="row">
                <div className="col-lg-4 col-md-6 w_mb-30">
                    <label className="w_label">Select Aggregator</label>
                    <div className="form-input">
                        <Select
                            className="w_select"
                            placeholder="Select Aggregator"
                            classNamePrefix="w_select"
                            isClearable={true}
                            isSearchable={true}
                            name="color"
                            options={aggregatorOptions}
                            maxMenuHeight={110}
                            value={allValues?.type}
                            onChange={(e) => handle(e, 'type')}
                        />
                        <span className="w_error">{validator?.current?.message('aggregator', allValues?.type, 'required')}</span>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 w_mb-30">
                    <label className="w_label">Enter Email ID</label>
                    <div className="form-input">
                        <input
                            type="text"
                            value={allValues?.email}
                            onChange={(e) => handle(e?.target?.value, 'email')}
                            placeholder="Enter Email ID"
                            className="form-control"
                        />
                        <span className="w_error">{validator?.current?.message('email', allValues?.email, 'required|email')}</span>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 w_mb-30">
                    <label className="w_label">Account Name</label>
                    <div className="form-input">
                        <input
                            type="text"
                            value={allValues?.name}
                            onChange={(e) => handle(e?.target?.value, 'name')}
                            placeholder="Account Name"
                            className="form-control"
                        />
                        <span className="w_error">{validator?.current?.message('name', allValues?.name, 'required')}</span>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-4 col-md-6 w_mb-30">
                    <label className="w_label">Account Token id</label>
                    <div className="form-input">
                        <input
                            type="text"
                            value={allValues?.token}
                            onChange={(e) => handle(e?.target?.value, 'token')}
                            placeholder="Account Token id"
                            className="form-control"
                        />
                        <span className="w_error">{validator?.current?.message('token', allValues?.token, 'required')}</span>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 w_mb-30">
                    <label className="w_label">Shipping Email</label>
                    <div className="form-input">
                        <input
                            type="text"
                            value={allValues?.shippingEmail}
                            onChange={(e) => handle(e?.target?.value, 'shippingEmail')}
                            placeholder="shipping Email"
                            className="form-control"
                        />
                        <span className="w_error">{validator?.current?.message('shippingEmail', allValues?.shippingEmail, 'email')}</span>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 w_mb-30">
                    <label className="w_label">Shipping Password</label>
                    <div className="form-input">
                        <input
                            type="password"
                            value={allValues?.shippingPassword}
                            onChange={(e) => handle(e?.target?.value, 'shippingPassword')}
                            placeholder="shipping Password"
                            className="form-control"
                        />
                        <span className="w_error">
                            {validator?.current?.message('shippingPassword', allValues?.shippingPassword, 'string')}
                        </span>
                    </div>
                </div>
            </div>
            {/* BlueDart Details */}
            <div className="row">
                <div className="col-lg-4 col-md-6 w_mb-30">
                    <label className="w_label">CustomerCode</label>
                    <div className="form-input">
                        <input
                            type="text"
                            value={allValues?.customerCode}
                            onChange={(e) => handle(e?.target?.value, 'customerCode')}
                            placeholder="BlueDart Customer Code"
                            className="form-control"
                        />
                        <span className="w_error">{validator?.current?.message('customerCode', allValues?.customerCode, 'string')}</span>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 w_mb-30">
                    <label className="w_label">LoginID</label>
                    <div className="form-input">
                        <input
                            type="text"
                            value={allValues?.loginID}
                            onChange={(e) => handle(e?.target?.value, 'loginID')}
                            placeholder="BlueDart Login Id"
                            className="form-control"
                        />
                        <span className="w_error">{validator?.current?.message('loginID', allValues?.loginID, 'string')}</span>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 w_mb-30">
                    <label className="w_label">Area</label>
                    <div className="form-input">
                        <input
                            type="text"
                            value={allValues?.area}
                            onChange={(e) => handle(e?.target?.value, 'area')}
                            placeholder="BlueDart Area code"
                            className="form-control"
                        />
                        <span className="w_error">{validator?.current?.message('area', allValues?.area, 'string')}</span>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-4 col-md-6 w_mb-30">
                    <label className="w_label">Licence Key</label>
                    <div className="form-input">
                        <input
                            type="password"
                            value={allValues?.licenceKey}
                            onChange={(e) => handle(e?.target?.value, 'licenceKey')}
                            placeholder="BlueDart Licence key"
                            className="form-control"
                        />
                        <span className="w_error">{validator?.current?.message('licenceKey', allValues?.licenceKey, 'string')}</span>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 w_mb-30">
                    <label className="w_label">Tracking Licence Key</label>
                    <div className="form-input">
                        <input
                            type="password"
                            value={allValues?.trackingLicenceKey}
                            onChange={(e) => handle(e?.target?.value, 'trackingLicenceKey')}
                            placeholder="BlueDart Tracking Licence key"
                            className="form-control"
                        />
                        <span className="w_error">
                            {validator?.current?.message('trackingLicenceKey', allValues?.trackingLicenceKey, 'string')}
                        </span>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 w_mb-30">
                    <label className="w_label">Pickup Address ID (ParcelX)</label>
                    <div className="form-input">
                        <input
                            type="text"
                            value={allValues?.pickupAddressID}
                            onChange={(e) => handle(e?.target?.value, 'pickupAddressID')}
                            placeholder="Pickup Address ID (ParcelX)"
                            className="form-control"
                        />
                        <span className="w_error">
                            {validator?.current?.message('pickupAddressID', allValues?.pickupAddressID, 'string')}
                        </span>
                    </div>
                </div>
            </div>

            <div className="row w">
                <div className="col-lg-4">
                    <button className="w-btn w-btn-blue" onClick={() => submit()}>
                        save
                    </button>
                    {allValues?.activeId && (
                        <button className="w-btn w-btn-cancel w_ml-15" onClick={() => clear()}>
                            Cancel
                        </button>
                    )}
                </div>
            </div>

            <div className="row w_mt-10">
                <div className="col-12">
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Aggregator</th>
                                    <th>Email</th>
                                    <th>Account Name</th>
                                    <th>Token</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {list?.map((item, i) => {
                                    return (
                                        <tr>
                                            <td>{aggregatorOptions?.find((e) => e?.value == item?.type)?.label}</td>
                                            <td>{item?.email}</td>
                                            <td>{item?.name}</td>
                                            <td className="w_note">
                                                <span className="w_block">{item?.token}</span>
                                            </td>
                                            <td>
                                                <button className="w-table-button w-table-button-save" onClick={() => editItem(item)}>
                                                    Edit
                                                </button>
                                                <br />
                                                <button
                                                    className="w-table-button w-table-button-danger"
                                                    onClick={() => deleteItem(item?.id)}
                                                >
                                                    Delete
                                                </button>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default AddAccount;
