import React, {useEffect, useRef, useState} from 'react';

// Packages
import PropTypes from 'prop-types';

// Hooks
// import useOutsideClick from "../hooks/useOutsideClick";
// import useEscKey from "../hooks/useEscKey";
// import useResizeHeight from "../hooks/useResizeHeight";

// Icons
// import {CloseIcon} from 'ubs-icons';
// import CheckBox from "../../../common/components/UCheckBox";

const SideModal = React.memo((props) => {
    const [open, setOpen] = useState(false);
    const ref = useRef(null);

    // useEscKey(() => {
    //     if (props?.open && !document.querySelector('.react-datepicker-popper')) props.onClose();
    // });

    // Scroll bar height - Hook
    // const scrollHeight = useResizeHeight(['u_side-modal-header', 'u_side-modal-footer']);
    // const scrollHeightHeader = useResizeHeight(['u_project-header']);

    useEffect(() => {
        setTimeout(() => setOpen(true), 10);
    }, [props?.open])

    const handleClose = () => {
        setTimeout(() => setOpen(false), 10);
        props.onClose()
    };
    const overlayClick = () => {
        if (props.outerClick) handleClose()
    }

    return (
        <React.Fragment>
            <div
                ref={ref}
                className={`u_side-modal ${open ? 'active' : ''} ${props?.className ? props?.className : ''}`}
            >
                {/* Modal Overlay */}
                {open && <div className="u_modal-overlay" onClick={overlayClick}/>}

                {/* Modal Content */}
                <div className="u_side-modal-layout" style={{maxWidth: `${props.maxWidth}px`}}>

                    {/* top: `${scrollHeightHeader}px`, maxHeight: `calc(100vh - ${scrollHeightHeader}px)` */}

                    {/* Modal header */}
                    <div className="u_side-modal-header u_flex u_align-center u_content-between" id="u_side-modal-header">
                        <div className="u_task-header-left">
                            {
                                props?.header ?
                                    props?.header
                                    :
                                    <h6 className="u_one-line u_task-darkname">{props.name}</h6>
                            }
                        </div>

                        <div className="u_task-header-right u_flex u_align-center">
                            <div className="u_cp u_lineheight-0" onClick={handleClose}>
                                {/* <CloseIcon/> */}
                            </div>
                        </div>
                    </div>

                    {/* Modal body */}
                    {/* style={{maxHeight: `calc(740px - ${scrollHeight}px)`}} */}
                    <div className="u_side-modal-body" id="u_side-modal-body" style={{maxHeight: `calc(740px - 250px)`}}>
                        {props.children}
                    </div>

                    {/* Modal footer */}
                    <div className="u_side-modal-footer" id="u_side-modal-footer">
                        {props.footer}
                    </div>

                </div>
            </div>
        </React.Fragment>
    );
})

SideModal.propTypes = {
    open: PropTypes.bool,
    name: PropTypes.string.isRequired,
    maxWidth: PropTypes.number,
    onClose: PropTypes.func.isRequired,
    footer: PropTypes.element,
    headerOptions: PropTypes.element,
    outerClick: PropTypes.bool
};

SideModal.defaultProps = {
    outerClick: true,
};

export default SideModal;