import React, { lazy, useEffect } from 'react';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';

// project imports
import MainLayout from './../layout/MainLayout';
import Loadable from '../ui-component/Loadable';
import AddWebsite from '../views/AddWebsite';
import AddStaff from '../views/AddStaff';
import Setting from '../views/Setting';
import { getToken } from '../store/constant';
import UploadCsv from '../views/UploadCsv';
import AllOrder from '../views/AllOrder';
import ReturnOrder from '../views/ReturnOrder';
import ReturnOrderList from '../views/ReturnOrderlist';
import ManageOrder from '../views/manage-order';
import ReturnPayment from '../views/return-payment';
import Report from '../views/report';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('../views/dashboard/Default')));
// utilities routing
const UtilsTypography = Loadable(lazy(() => import('../views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('../views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('../views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('../views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('../views/utilities/TablerIcons')));

// sample page routing
const SamplePage = Loadable(lazy(() => import('../views/sample-page')));

//-----------------------|| MAIN ROUTING ||-----------------------//

const MainRoutes = React.memo(() => {
    const location = useLocation();
    const token = getToken();
    return (
        <Route
            path={[
                '/dashboard/default',
                '/add-website',
                '/add-staff',
                '/add-account',
                '/upload-csv',
                '/orders/all-order/:id',
                '/orders/return-order-form/:id',
                '/orders/return-order-list/:id',
                '/icons/tabler-icons',
                '/manage-order/:id',
                '/return-payment',
                '/settings/:id',
                '/report/:id',
                '/icons/material-icons',

                '/sample-page'
            ]}
        >
            <MainLayout>
                <Switch location={location} key={location.pathname}>
                
                    <PrivateRoute path="/add-website" component={AddWebsite}   auth={token}/>
                    <PrivateRoute path="/add-staff" component={AddStaff}  auth={token}/>
                    <PrivateRoute path="/upload-csv" component={UploadCsv}  auth={token}/>
                    <PrivateRoute path="/orders/all-order/:id" component={AllOrder}  auth={token}/>
                    <PrivateRoute path="/orders/return-order-form/:id" component={ReturnOrder}  auth={token}/>
                    <PrivateRoute path="/orders/return-order-list/:id" component={ReturnOrderList}  auth={token}/>
                    <PrivateRoute path="/manage-order/:id" component={ManageOrder}  auth={token}/>
                    <PrivateRoute path="/return-payment" component={ReturnPayment}  auth={token}/>
                    <PrivateRoute path="/settings/:id" component={Setting}  auth={token}/>
                    <PrivateRoute path="/report/:id" component={Report}  auth={token}/>
                    <PrivateRoute path="/icons/tabler-icons" component={UtilsTablerIcons}  auth={token}/>
                    <PrivateRoute path="/icons/material-icons" component={UtilsMaterialIcons}  auth={token}/>
                  
                </Switch>
            </MainLayout>
        </Route>
    );
});

export default MainRoutes;

const PrivateRoute = ({component: Component, auth}) => (
    <Route render={props => auth?.length > 0
      ? <Component {...props} />
      : <Redirect to={{pathname:'/login'}} />
    }
    />
  )
