import React, { useEffect, useRef, useState } from 'react';

// project imports
import MainCard from '../../ui-component/cards/MainCard';
import SimpleReactValidator from 'simple-react-validator';
import { useForceUpdate } from '../../hooks/useForceUpdate';
import { addUser, updateUser, userList } from '../../Services/service';
import { showToastError, showToastSuccess } from '../../store/constant';

const AddStaff = () => {

    const forceUpdate = useForceUpdate()
    const validator = useRef(new SimpleReactValidator({ element: (message, className) => <div className={'u_error'}>{message}</div> }));
    const [list, setList] = useState([])
    const [allValues, setAllValues] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        activeId: ''
    })

    const handle = (e, name) => {
        setAllValues({ ...allValues, [name]: e })
    }

    useEffect(() => {
        getUser();
    }, [])

    const clear = () => {
        setAllValues({
            ...allValues,
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            activeId: ''
        })
        validator?.current?.hideMessages();
    }

    const getUser = () => {
        userList()?.then((res) => {
            if (!res?.data?.error) {
                setList(res?.data?.data?.rows);
            }
            else {
                showToastError(res?.data?.message)
            }
        })
    }


    const submit = () => {
        if(allValues?.activeId && validator?.current) validator.current.fields['password'] = true
        if (validator?.current?.allValid()) {
            let param = {
                firstName: allValues?.firstName,
                lastName: allValues?.lastName,
                email: allValues?.email,
                password: allValues?.password
            }
            if(allValues?.activeId){
                param.id= allValues?.activeId
                updateUser(param).then(res => {
                    if (!res?.data?.error) {
                        clear();
                        getUser();
                        showToastSuccess('User Updated.')
                    }
                    else {
                        showToastError(res?.data?.data?.error || res?.data?.message)
                    }
                })
            }
            else{
                addUser(param).then(res => {
                    if (!res?.data?.error) {
                        clear();
                        getUser();
                        showToastSuccess(res?.data?.data?.message)
                    }
                    else {
                        showToastError(res?.data?.data?.error || res?.data?.message)
                    }
                })
            }
        
        } else {
            validator?.current?.showMessages();
            forceUpdate();
        }
    }

    const deleteItem =(item)=>{
            let param={
                isDeleted: item?.isDeleted == 1 ? 0 : 1,
                id: item?.id
            }
                updateUser(param).then(res => {
                    if (!res?.data?.error) {
                        clear();
                        getUser();
                        showToastSuccess('User Updated.')
                    }
                    else {
                        showToastError(res?.data?.data?.error || res?.data?.message)
                    }
                })
    }

    const editItem = (e) => {
        setAllValues({
            ...allValues,
            firstName: e?.firstName,
            lastName: e?.lastName,
            email: e?.email,
            activeId: e?.id
        })
    }

    return (
        <MainCard title="Add Staff">
            <div className="row">
                <div className="col-lg-4 col-md-6 w_mb-30">
                    <label className="w_label">First Name</label>
                    <div className="form-input">
                        <input type="text" value={allValues?.firstName} onChange={(e) => handle(e?.target?.value, 'firstName')} placeholder="Add First Name" className="form-control" />
                        <span className="w_error">{validator?.current?.message('first name', allValues?.firstName, 'required')}</span>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 w_mb-30">
                    <label className="w_label">Last Name</label>
                    <div className="form-input">
                        <input type="text" value={allValues?.lastName} onChange={(e) => handle(e?.target?.value, 'lastName')} placeholder="Add Last Name" className="form-control" />
                        <span className="w_error">{validator?.current?.message('last name', allValues?.lastName, 'required')}</span>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-4 col-md-6 w_mb-30">
                    <label className="w_label">Enter your email</label>
                    <div className="form-input">
                        <input type="text" value={allValues?.email} onChange={(e) => handle(e?.target?.value, 'email')} placeholder="Enter your email" className="form-control" />
                        <span className="w_error">{validator?.current?.message('email', allValues?.email, 'required|email')}</span>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 w_mb-30">
                    <label className="w_label">Enter Password</label>
                    <div className="form-input">
                        <input type="password" value={allValues?.password} onChange={(e) => handle(e?.target?.value, 'password')} placeholder="Enter Password" className="form-control" />
                        <span className="w_error">{validator?.current?.message('password', allValues?.password, 'required|min:6,string')}</span>
                    </div>
                </div>
            </div>
            <div className="row w_mb-20">
                <div className="col-lg-4">
                  <button className="w-btn w-btn-blue" onClick={() => submit()}>save</button>
                   {allValues?.activeId && <button className="w-btn w-btn-cancel w_ml-15" onClick={() => clear()}>Cancel</button>}
                </div>
               
            </div>

            <div className="row w_mt-10">   
                <div className="col-12">
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>password</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    list?.map((item, i) => {
                                        return (
                                            <tr>
                                                <td>{`${item?.firstName} ${item?.lastName}`}</td>
                                                <td>{item?.email}</td>
                                                <td>#########</td>
                                                <td>
                                                    <button className="w-table-button w-table-button-save w_mr-15" onClick={() => editItem(item)}>Edit</button>
                                                  { item?.isDeleted !== null && <button className="w-table-button w-table-button-danger" onClick={() => deleteItem(item)}>{item?.isDeleted ==1 ? 'UnBlock' : 'Block'}</button>}
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </MainCard>
    );
};

export default AddStaff;
