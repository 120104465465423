import React from 'react';

const Template = () => {
    return (
        <React.Fragment>
            {/* Templete */}
            <div className="row w_mb-30">
                <div className="col-6">
                    <div className="w_template">
                        <table
                            border="1px"
                            style={{ borderCollapse: 'collapse', width: '500px', fontFamily: 'Arial, sans-serif', borderColor: '#ededed' }}
                        >
                            <tbody>
                                {/* <tr colspan="2" style={{ fontSize: '20px',textAlign:'center'}}>
                                    <td style={{padding:'10px'}}>Brand Name</td>
                                </tr> */}
                                <tr>
                                    <td
                                        colspan="2"
                                        style={{
                                            padding: '12px',
                                            position: 'relative',
                                            textAlign: 'center',
                                            fontSize: '22px'
                                        }}
                                    >
                                        Brand name
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ fontSize: '14px', padding: '10px' }}>
                                        <h4 class="top-head" style={{ marginTop: '0', marginBottom: '0px' }}>
                                            Shipping Address :
                                        </h4>
                                        <h3 style={{ marginTop: '5px', marginBottom: '8px' }}>Karpagam Vinoth Kumar</h3>
                                        <p style={{ marginTop: '0px', marginBottom: '8px' }}>
                                            <span style={{ fontWeight: 'bold' }}>Phone:</span> 8939203062
                                        </p>
                                        <p style={{ marginTop: '0px', marginBottom: '8px' }}>
                                            3/476 MGR nagar 2street kovilambakkam Veeramani bus stop Chennai TN 600129 IN
                                        </p>
                                        <p style={{ marginTop: '0px', marginBottom: '8px' }}>KANCHIPURAM, TAMIL NADU</p>
                                        <p style={{ marginTop: '0px', marginBottom: '0px' }}>
                                            <span style={{ fontWeight: 'bold' }}>PINCODE</span>: 600129
                                        </p>
                                    </td>

                                    <td
                                        colspan="2"
                                        style={{
                                            padding: '0 40px',
                                            position: 'relative',
                                            textAlign: 'center',
                                            fontSize: '22px'
                                        }}
                                    >
                                        <img
                                            src="https://staging.whiteorangesoftware.com/selleraxis/assets/images/last-cat-img-1.svg"
                                            style={{ width: '108px', height: '100px', objectFit: 'contain' }}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ fontSize: '14px', padding: '10px' }}>
                                        <p style={{ marginTop: '0', marginBottom: '0px' }}>
                                            <span style={{ fontWeight: 'bold' }}>Product Id:</span>
                                            <span> 01245632</span>
                                        </p>
                                    </td>
                                    <td style={{ fontSize: '14px', textAlign: 'center' }}>
                                        <p tyle={{ marginTop: '0', marginBottom: '8px' }}>
                                            <span style={{ fontWeight: 'bold' }}>QTY :</span>
                                            <span> 1</span>
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2" style={{ fontSize: '14px', padding: '10px' }}>
                                        <p style={{ marginTop: '0', marginBottom: '0px' }}>
                                            <span style={{ fontWeight: 'bold' }}>SKU:</span>
                                            <span> BHHJSF456213</span>
                                        </p>
                                    </td>
                                </tr>

                                <tr>
                                    <td style={{ fontSize: '14px', padding: '10px' }}>
                                        <p style={{ marginTop: '0', marginBottom: '0px' }}>
                                            <span style={{ fontWeight: 'bold', paddingBottom: '10px' }}>Courier Name:</span>
                                            <span> Ecom COD</span>
                                        </p>
                                        <p style={{ marginTop: '0', marginBottom: '0px' }}>
                                            <span style={{ fontWeight: 'bold' }}>Courier AWB No:</span>
                                            <span>: FMPC1331623201</span>
                                        </p>
                                    </td>
                                    <td style={{ fontSize: '14px', padding: '10px' }}>
                                        <p style={{ marginTop: '0', marginBottom: '0px' }}>
                                            <span style={{ fontWeight: 'bold' }}>Order Date:</span>
                                            <span> 25/9/2021</span>
                                        </p>
                                        <p style={{ marginTop: '0', marginBottom: '0px' }}>
                                            <span style={{ fontWeight: 'bold' }}>Weight:</span>
                                            <span> 250gm</span>
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2" style={{ fontSize: '14px', padding: '10px' }}>
                                        <p style={{ marginTop: '0', marginBottom: '0px' }}>
                                            <span style={{ fontWeight: 'bold' }}>Tracking ID:</span>
                                            <span> FMPC1331623201</span>
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2" style={{ fontSize: '14px', padding: '10px' }}>
                                        <p style={{ marginTop: '0', marginBottom: '0px' }}>
                                            <span style={{ fontWeight: 'bold' }}>GSTIN No:</span>
                                            <span> FSFGSC1331623201</span>
                                        </p>
                                    </td>
                                </tr>

                                <tr>
                                    <td
                                        colspan="2"
                                        style={{
                                            padding: '40px',
                                            position: 'relative',
                                            textAlign: 'center',
                                            fontSize: '22px'
                                        }}
                                    >
                                        <span class="barcode">1348632055386</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        class="pad-head"
                                        colspan="2"
                                        style={{ padding: '10px', marginTop: '0px', marginBottom: '8px', fontWeight: '900' }}
                                    >
                                        AMOUNT TO BE COLLECTED (COD) : Rs 1148.0
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2" style={{ padding: '10px' }}>
                                        <span style={{ fontWeight: 'bold' }}>Return Address:</span>
                                        <span> 1st flore HHM Asted,</span> <br></br>
                                        <span> BRTS Road,Udhna surat,GUJARAT</span> <br></br>
                                        <span> SURAT - 395002,</span> <br></br>
                                        <span> Ph : 9999999999</span>
                                        <h4 style={{ marginTop: '5px', marginBottom: '10px' }}>Sold By</h4>
                                        <h4 style={{ marginTop: '5px', marginBottom: '10px', fontSize: '16px' }}>Brand Name</h4>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="col-6">
                    <div className="w_template">
                        <table
                            border="1px"
                            style={{ borderCollapse: 'collapse', width: '500px', fontFamily: 'Arial, sans-serif', borderColor: '#ededed' }}
                        >
                            <tbody>
                                <tr>
                                    <td colspan="3" style={{ fontSize: '14px', padding: '10px' }}>
                                        <p style={{ fontWeight: 'bold', paddingBottom: '0px' }}>SHIP TO</p>
                                        <p style={{ marginTop: '0px', marginBottom: '3px' }}>
                                            <span style={{ fontWeight: 'bold' }}>NAME :</span> NALINI S
                                        </p>
                                        <p style={{ marginTop: '0px', marginBottom: '2px' }}>
                                            <span style={{ fontWeight: 'bold' }}>Phone:</span> 8939203062
                                        </p>
                                        <p style={{ marginTop: '0px', marginBottom: '2px' }}>
                                            Road Number 3 A.S. Rao Nagar <br></br>303 block b la cresta apartments<br></br>asrao nagar
                                            Secunderabad TS
                                        </p>
                                        <p style={{ marginTop: '0px', marginBottom: '0px' }}>
                                            <span style={{ fontWeight: 'bold' }}>PINCODE</span>: 600129
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        colspan="2"
                                        style={{
                                            padding: '40px',
                                            position: 'relative',
                                            textAlign: 'center',
                                            fontSize: '22px'
                                        }}
                                    >
                                        <img
                                            src="https://staging.whiteorangesoftware.com/selleraxis/assets/images/last-cat-img-1.svg"
                                            style={{ width: '108px', height: '100px', objectFit: 'contain' }}
                                        />
                                    </td>
                                    <td
                                        style={{
                                            padding: '40px',
                                            position: 'relative',
                                            textAlign: 'center',
                                            fontSize: '22px'
                                        }}
                                    >
                                        WEBSIDE NAME
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ fontSize: '14px', padding: '10px', fontWeight: 'bold' }}>Secunderabad</td>
                                    <td style={{ fontSize: '14px', padding: '10px', fontWeight: 'bold' }}>HYDERABAD</td>
                                    <td style={{ fontSize: '14px', padding: '10px', fontWeight: 'bold' }}>TELANGANA</td>
                                </tr>

                                <tr>
                                    <td colspan="3" style={{ fontSize: '14px', padding: '10px' }}>
                                        <p style={{ marginTop: '0', marginBottom: '3px' }}>
                                            <span style={{ fontWeight: 'bold' }}>PINCODE:</span>
                                            <span> 500062</span>
                                        </p>
                                        <p style={{ marginTop: '0', marginBottom: '03px' }}>
                                            <span style={{ fontWeight: 'bold' }}>ROUTE CODE :</span>
                                            <span> HYD/NMT</span>
                                        </p>
                                        <p style={{ marginTop: '0', marginBottom: '03px' }}>
                                            <span style={{ fontWeight: 'bold' }}>AWB :</span>
                                            <span> 78522361234</span>
                                        </p>
                                    </td>
                                </tr>

                                <tr>
                                    <td
                                        colspan="3"
                                        style={{
                                            padding: '40px',
                                            position: 'relative',
                                            textAlign: 'center',
                                            fontSize: '22px'
                                        }}
                                    >
                                        <span class="barcode">1348632055386</span>
                                    </td>
                                </tr>

                                <tr>
                                    <td style={{ fontSize: '14px', padding: '10px' }}>
                                        <p style={{ marginTop: '0', marginBottom: '3px' }}>
                                            <span style={{ fontWeight: 'bold' }}>Order Id :</span>
                                            <span style={{ fontWeight: 'bold' }}> WI5713660</span>
                                        </p>
                                        <p style={{ marginTop: '0', marginBottom: '3px' }}>
                                            <span style={{ fontWeight: 'bold' }}>Order Date:</span>
                                            <span> 25/9/2021</span>
                                        </p>
                                        <p style={{ marginTop: '0', marginBottom: '3px' }}>
                                            <span style={{ fontWeight: 'bold' }}>Weight:</span>
                                            <span> 250gm</span>
                                        </p>
                                        <p style={{ marginTop: '0', marginBottom: '0px' }}>
                                            <span style={{ fontWeight: 'bold' }}>SKU:</span>
                                            <span> FMPC1331623201</span>
                                        </p>
                                    </td>
                                    <td colspan="2" style={{ textAlign: 'center', fontSize: '18px', padding: '10px', fontWeight: 'bold' }}>
                                        COD AMOUNT RS.2097
                                    </td>
                                </tr>
                                <tr></tr>
                                <tr class="text-center">
                                    <td colspan="3" style={{ textAlign: 'center', padding: '10px' }}>
                                        <span style={{ fontWeight: 'bold' }}>Return Address:</span>
                                        <span> 1st flore HHM Asted,</span> <br></br>
                                        <span> BRTS Road,Udhna surat,GUJARAT</span> <br></br>
                                        <span> SURAT - 395002,</span> <br></br>
                                        <span> Ph : 9999999999</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="row w_mb-30">
                <div className="col-6">
                    <div className="w_template">
                        <table
                            border="1px"
                            style={{ borderCollapse: 'collapse', width: '500px', fontFamily: 'Arial, sans-serif', borderColor: '#ededed' }}
                        >
                            <tbody>
                                <tr>
                                    <td
                                        colspan="7"
                                        style={{
                                            padding: '40px',
                                            position: 'relative',
                                            textAlign: 'center',
                                            fontSize: '22px'
                                        }}
                                    >
                                        <span class="barcode">1348632055386</span>
                                    </td>
                                    <td
                                        colspan="4"
                                        style={{
                                            padding: '40px',
                                            position: 'relative',
                                            textAlign: 'center',
                                            fontSize: '22px',
                                            fontWeight: 'bold'
                                        }}
                                    >
                                        COD AMOUNT RS.2097
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="5" style={{ fontSize: '14px', padding: '10px' }}>
                                        <p style={{ fontWeight: 'bold', marginBottom: '3px' }}>SHIP TO</p>
                                        <p style={{ marginTop: '0px', marginBottom: '10px', fontWeight: 'bold' }}>
                                            <span>NAME :</span> NALINI S
                                        </p>
                                        <p style={{ marginTop: '0px', marginBottom: '5px', fontWeight: 'bold' }}>
                                            <span>Phone:</span> 8939203062
                                        </p>
                                        <p style={{ marginTop: '0px', marginBottom: '2px' }}>
                                            Road Number 3 A.S. Rao Nagar <br></br>303 block b la cresta apartments<br></br>asrao nagar
                                            Secunderabad TS <br></br>
                                        </p>
                                        <p style={{ marginTop: '0px', marginBottom: '2px' }}>500062</p>
                                        <p style={{ marginTop: '20px', marginBottom: '0px' }}>
                                            <span style={{ fontWeight: 'bold' }}>PINCODE</span>: 600129
                                        </p>
                                    </td>
                                    <td colspan="3" style={{ textAlign: 'center' }}>
                                        <span
                                            style={{
                                                display: 'inline-block',
                                                padding: '15px',
                                                position: 'relative',
                                                textAlign: '',
                                                fontSize: '22px'
                                            }}
                                        >
                                            <img
                                                src="https://staging.whiteorangesoftware.com/selleraxis/assets/images/last-cat-img-1.svg"
                                                style={{ width: '108px', height: '100px', objectFit: 'contain' }}
                                            />
                                        </span>
                                        <tr colspan="" style={{ textAlign: 'left', width: '100%' }}>
                                            <td
                                                colspan=""
                                                style={{ fontSize: '14px', padding: '10px', paddingBottom: '10px', width: '44%' }}
                                            >
                                                <p style={{ marginTop: '0px', marginBottom: '5px' }}>
                                                    <span style={{ fontWeight: 'bold', paddingBottom: '' }}>Order Id:</span>
                                                    <span style={{ fontWeight: 'bold', paddingBottom: '' }}> WI5713660</span>
                                                </p>
                                                <p style={{ marginTop: '0', marginBottom: '5px' }}>
                                                    <span style={{ fontWeight: 'bold' }}>Order Date:</span>
                                                    <span> 25/9/2021</span>
                                                </p>
                                                <p style={{ marginTop: '0', marginBottom: '0px' }}>
                                                    <span style={{ fontWeight: 'bold' }}>AWB :</span>
                                                    <span> 78522361234</span>
                                                </p>
                                            </td>
                                            <td
                                                colspan=""
                                                style={{ fontSize: '14px', padding: '10px', paddingBottom: '10px', width: '44%' }}
                                            >
                                                <p style={{ marginTop: '0', marginBottom: '0px' }}>
                                                    <span style={{ fontWeight: 'bold' }}>Weight:</span>
                                                    <span> 250gm</span>
                                                </p>
                                            </td>
                                        </tr>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2" style={{ fontSize: '14px', padding: '10px', fontWeight: 'bold' }}>
                                        Secunderabad
                                    </td>
                                    <td colspan="2" style={{ fontSize: '14px', padding: '10px', fontWeight: 'bold' }}>
                                        HYDERABAD
                                    </td>
                                    <td colspan="2" style={{ fontSize: '14px', padding: '10px', fontWeight: 'bold' }}>
                                        TELANGANA
                                    </td>

                                    <td colspan="3" style={{ fontSize: '14px', padding: '10px', fontWeight: 'bold' }}>
                                        SKU : 708-707
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="11" style={{ textAlign: 'center', padding: '10px' }}>
                                        <span style={{ fontWeight: 'bold' }}>Return Address:</span>
                                        <span> 1st flore HHM Asted,</span>
                                        <span> BRTS Road,Udhna surat,GUJARAT</span>
                                        <span> SURAT - 395002,</span> <br></br>
                                        <span> Ph : 9999999999</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="col-6">
                    <div className="w_template">
                        <table
                            border="1px"
                            style={{ borderCollapse: 'collapse', width: '500px', fontFamily: 'Arial, sans-serif', borderColor: '#ededed' }}
                        >
                            <tbody>
                                <tr>
                                    <td style={{ fontSize: '14px', padding: '10px' }}>
                                        <p style={{ marginBottom: '0px' }}>Ship To</p>
                                        <p style={{ marginTop: '0px', marginBottom: '0px' }}>Anuradha Jogpete.</p>
                                        <p style={{ marginTop: '0px', marginBottom: '2px' }}>
                                            Lic Colony Pragati Nagar,Venkatesh Prasad<br></br>Apartment<br></br>Venkatesh Prasad <br></br>
                                            Latur,Maharashtra,India<br></br>
                                        </p>
                                        <p style={{ marginTop: '0px', marginBottom: '2px' }}>413512</p>
                                        <p style={{ marginTop: '0px', marginBottom: '5px' }}>
                                            <span>Phone No:</span> 9309750479
                                        </p>
                                    </td>
                                    <td
                                        colspan="7"
                                        style={{
                                            padding: '40px',
                                            position: 'relative',
                                            textAlign: 'center',
                                            fontSize: '22px'
                                        }}
                                    >
                                        <img
                                            src="https://staging.whiteorangesoftware.com/selleraxis/assets/images/last-cat-img-1.svg"
                                            style={{ width: '108px', height: '100px', objectFit: 'contain' }}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ fontSize: '14px', padding: '10px' }}>
                                        <p style={{ marginBottom: '0px' }}>
                                            <span>Dimensions :</span>
                                            <span> 5.00*5.00x2.00</span>
                                        </p>
                                        <p style={{ marginTop: '0px', marginBottom: '2px' }}>
                                            <span>Payment :</span>
                                            <span style={{ fontWeight: 'bold' }}>COD</span>
                                        </p>
                                        <p style={{ fontWeight: 'bold', marginTop: '0px', marginBottom: '2px' }}>
                                            <span>COD Amount:</span>
                                            <span>1199.00 INR </span>
                                        </p>
                                        <p style={{ marginTop: '0px', marginBottom: '2px' }}>
                                            <span>Weight:</span>
                                            <span>0.45KG</span>
                                        </p>
                                        <p style={{ marginTop: '0px', marginBottom: '0px' }}>
                                            <span>E-Waybill No.:</span>
                                            <span>N/A</span>
                                        </p>
                                    </td>
                                    <td
                                        colspan="7"
                                        style={{
                                            padding: '40px',
                                            position: 'relative',
                                            textAlign: 'center',
                                            fontSize: '22px'
                                        }}
                                    >
                                        <span class="barcode">1348632055386</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ fontSize: '14px', padding: '10px' }}>
                                        <p style={{ marginTop: '0px', marginBottom: '2px' }}>Shipped By(If undelivered,return to)</p>
                                        <p style={{ marginTop: '0px', marginBottom: '2px' }}>WEBSIDE NAME</p>
                                        <p style={{ marginTop: '0px', marginBottom: '2px' }}>First Flore Plote-10 SSM Nagar,Nr</p>
                                        <p style={{ marginTop: '0px', marginBottom: '2px' }}>Clothing Store,BRTS Road,Udhna</p>
                                        <p style={{ marginTop: '0px', marginBottom: '2px' }}>Surat</p>
                                        <p style={{ marginTop: '0px', marginBottom: '2px' }}>Surat : 395002</p>

                                        <p style={{ marginTop: '0', marginBottom: '0px' }}>
                                            <span style={{}}>GSTIN No:</span>
                                            <span> FSFGSC1331623201</span>
                                        </p>
                                        <p style={{ marginTop: '0px', marginBottom: '8px' }}>
                                            <span style={{}}>Phone:</span> 8939203062
                                        </p>
                                    </td>
                                    <td
                                        colspan="7"
                                        style={{
                                            padding: '40px',
                                            position: 'relative',
                                            textAlign: 'center',
                                            fontSize: '22px'
                                        }}
                                    >
                                        <span class="barcode">1348632055386</span>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Product Name & SKU</th>
                                    <th>HSN</th>
                                    <th>Qty</th>
                                    <th>Unit price</th>
                                    <th>Taxable Value</th>
                                    <th>IGST</th>
                                    <th>Total</th>
                                </tr>
                                <tr>
                                    <td style={{ fontSize: '14px', padding: '10px' }}>
                                        Silk saree pack of 2 saree set<br></br>SKU:1008-201
                                    </td>
                                    <td style={{ fontSize: '14px', padding: '10px' }}></td>
                                    <td style={{ fontSize: '14px', padding: '10px' }}>1</td>
                                    <td style={{ fontSize: '14px', padding: '10px' }}>1199.00</td>
                                    <td style={{ fontSize: '14px', padding: '10px' }}>1199.00</td>
                                    <td style={{ fontSize: '14px', padding: '10px' }}>0.00</td>
                                    <td style={{ fontSize: '14px', padding: '10px' }}>1199.00</td>
                                </tr>
                                <tr>
                                    <td colspan="7" style={{ padding: '25px' }}></td>
                                </tr>
                                <tr>
                                    <td colspan="7" style={{ fontSize: '14px', padding: '10px' }}>
                                        All disputes are subject to Gujarat jurisdiction.Goods once sold will only be taken back or
                                        exchanged as per the store's exchange/return policy.
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="7" style={{ fontSize: '14px', padding: '10px' }}>
                                        <p>THIS IS AN AUTO-GENERATED LABEL AND DOES NOT NEED SIGNATURE</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Template;
